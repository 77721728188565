import React from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  List,
  Divider,
  TextField,
  Button,
} from "@mui/material";

import { Datas, EventDatas } from "../../../common/types";

type Props = {
  datas: Datas;
  eventDatas: EventDatas;
};

const MenuEventBeforeBook: React.VFC<Props> = ({
  datas,
  eventDatas,
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h5" component="h2" gutterBottom>
            {/* イベントID: {eventid} */}
            {eventDatas.eventData.title}
          </Typography>
          <br />
          日程：{eventDatas.eventData.eventDateString}
          <br />
          駅名：{eventDatas.eventData.eki}
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: eventDatas.eventData.comment,
            }}
          />
          <br />
          <div style={{ color: datas.bgColor.back01 }}>
            {" "}
            {eventDatas.eventData.id}
          </div>
          <br />
          {!datas.userInfo.isLogin ? (
            <div style={{ textAlign: "center" }}>
              <br />
              まずは
              <Button
                style={{
                  display: "inline-block",
                  padding: "10px 10px",
                  fontSize: "18px",
                  color: "#fff",
                  backgroundColor: "#CC9900",
                  textDecoration: "none",
                  borderRadius: "5px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => {
                  navigate("/Book/signin");
                }}
              >
                ログイン
              </Button>
              しましょう
            </div>
          ) : eventDatas.isParticipation ? (
            <>ここは表示されないはず</>
          ) : (
            <>
              <Button
                style={{
                  display: "inline-block",
                  padding: "10px 10px",
                  fontSize: "18px",
                  color: "#fff",
                  backgroundColor: datas.bgColor.back06,
                  textDecoration: "none",
                  borderRadius: "5px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s ease",
                  width: "100%",
                }}
                onClick={() => {
                  eventDatas.bookUser(
                    eventDatas.eventData.id,
                    eventDatas.eventData.title,
                    eventDatas.eventData.eventDate
                  );
                }}
              >
                参加
              </Button>

              {/* <Button
                style={{
                  display: "inline-block",
                  padding: "10px 10px",
                  fontSize: "18px",
                  color: "#fff",
                  backgroundColor: "#b2d8b2", // 薄い緑色に変更
                  textDecoration: "none",
                  borderRadius: "5px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s ease",
                  width: "100%",
                  cursor: "pointer", // マウスホバー時のカーソルをポインターに変更
                }}
                onClick={() => {
                  eventDatas.setPointmodalopen(true);
                }}
              >
                ×工事中×
              </Button> */}
            </>
          )}
          <br />
          <br />
          <br />
          <u>🔳 イベント参加者</u>
          <br />
          {eventDatas.eventParticipantsList.map((item: any, index: any) => (
            <>{item.name}:</>
          ))}
          <br />
          <br />
          <br />
        </Paper>
        <br />
      </Container>
    </>
  );
};
export default MenuEventBeforeBook;
