import { useEffect, useState } from "react";
import { Container, Grid, Button, Paper, Typography, Box } from "@mui/material";
import createCheckoutSessionOneBuy from "../../../common/stripeHelper/createCheckoutSesiionOneBuy";

// Datas と PaymentDatas の型をインポート
import type { Datas, PaymentDatas } from "../../../common/types";

type Props = {
  datas: Datas;
  paymentDatas: PaymentDatas;
};

const SinglePurchase: React.VFC<Props> = ({ datas }: Props) => {
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);

  useEffect(() => {
    console.log("uE_AddPoint");
  }, []);

  const handlePurchase = (userUid: string, planId: string) => {
    setLoadingModalOpen(true);
    createCheckoutSessionOneBuy(userUid, planId);
    // Add further actions if necessary
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h2" align="center" gutterBottom>
          ポイント購入
        </Typography>
        <br />

        <Grid container spacing={3} justifyContent="center">
          {[
            {
              id: "onebuy100",
              label: "100ポイント購入",
              bonus: "今だけボーナス無料ポイント200ポイント",
              pickup: true,
            },
            {
              id: "onebuy1000",
              label: "1000ポイント購入",
              bonus: "ボーナス無料ポイント100ポイント",
              pickup: false,
            },
            {
              id: "onebuy10000",
              label: "10000ポイント購入",
              bonus: "ボーナス無料ポイント3000ポイント",
              pickup: false,
            },
          ].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box textAlign="center" sx={{ mb: 2 }}>
                <Button
                  size="large"
                  variant="contained"
                  onClick={() =>
                    handlePurchase(datas.userInfo.userUid, item.id)
                  }
                  sx={{
                    fontSize: 15,
                    minWidth: "200px",
                    mb: 1,
                    backgroundColor: "#7BAB4F", // 山手色
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#6A9E47", // 少し濃い山手色
                    },
                  }}
                >
                  {item.label}
                </Button>
                <Typography
                  variant="caption"
                  sx={{
                    whiteSpace: "nowrap",
                    display: "block",
                    color: item.pickup ? "red" : "black", // pickupがtrueなら赤、falseなら黒
                    fontWeight: item.pickup ? "bold" : "normal", // pickupがtrueなら太字
                  }}
                >
                  {item.bonus}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Box sx={{ height: "2vh" }}></Box>
    </Container>
  );
};

export default SinglePurchase;
