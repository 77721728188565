import React, { useRef, useState } from "react";
import { Virtual, Navigation, Pagination } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "./styles.css";
import { useNavigate } from "react-router-dom";

import Hara1 from "./pic/Event202312_3.jpeg";
import Kou1 from "./pic/Event20230826_20.jpeg";
import Ebisu1 from "./pic/Event20240303_4.png";
import Tokyo1 from "./pic/Event20240616_2.png";

import { Button, Grid } from "@mui/material";

import EventBlockShow from "./EventBlockShow";

type Props = {
  eventPastList: any;
};

const PastEvents: React.VFC<Props> = ({ eventPastList }: Props) => {
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>();
  const appendNumber = useRef(10);
  const prependNumber = useRef(1);

  const [illustrations, setIllustrations] = useState([
    {
      id: "vsbHCzSuMxbJkxxECgGZ",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/boukenguild.appspot.com/o/YamanoteRoute%2FIVPVkrAByzXCmvrELfHF%2FGroupPhoto%2Fuserupload%2F1737406870158?alt=media&token=f7cd627d-7899-471e-aa95-a5a88927cb1f",
      alt: "Illustration 5",
      location: "秋葉原",
      text: "秋葉原散歩",
      date: "2025/01/18(土)",
      clickAction: "Record",
    },
    {
      id: "z62EpzqqrnNZ1kiGemcU",
      imageUrl: Tokyo1,
      alt: "Illustration 4",
      location: "東京",
      text: "東京散歩",
      date: "2024/06/16(日)",
      clickAction: "",
    },
    {
      id: "wtWc0mvukl0KlV7wlJIF",
      imageUrl: Ebisu1,
      alt: "Illustration 3",
      location: "恵比寿",
      text: "恵比寿散歩",
      date: "2024/03/03(日)",
      clickAction: "",
    },
    {
      id: "4cEAF9wLv0xRWDfwcBrE",
      imageUrl: Hara1,
      alt: "Illustration 2",
      location: "原宿",
      text: "原宿散歩",
      date: "2023/12/10(日)",
      clickAction: "",
    },
    {
      id: "peAHnWBg1EH8g1hEtq4w",
      imageUrl: Kou1,
      alt: "Illustration 1",
      location: "千駄木",
      text: "交流会",
      date: "2023/08/26(土)",
      clickAction: "",
    },
  ]);

  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={3} padding={2}>
        {illustrations.map((illustration, index) => (
          <Grid item xs={6} sm={4} md={3} lg={3} key={illustration.id}>
            <EventBlockShow
              events={[
                {
                  id: illustration.id,
                  title: illustration.text,
                  date: illustration.date,
                  location: illustration.location + "駅",
                  image: illustration.imageUrl,
                  soldOut: false,
                  participants: [],
                  communityInfo: "公式イベント",
                  clickAction: illustration.clickAction,
                },
              ]}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default PastEvents;
