import { useEffect, useState, useMemo } from "react";
import { Datas } from "../../../common/types";
import { useParams } from "react-router-dom";

import { otherApp } from "../../../common/firebaseHelper/firebase";

import {
  getEvents,
  setEventParticipants,
  deleteEventParticipants,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";
import { getUserPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";
import {
  setUserPrivateBook,
  getUserPrivateBooks,
  deleteUserPrivateBook,
  getUserPrivatePlan,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

import LodingBook from "./LodingBook";

import BookView from "./BookView";

import LodingModal from "../../Modal/LodingModal/LodingModal";

import moment from "moment";
import "moment/locale/ja"; // 日本語ロケールをインポート

type Props = {
  datas: Datas;
};

const Book: React.FC<Props> = ({ datas }) => {
  // const navigate = useNavigate();

  let { opendmenu } = useParams();

  const [eventList, setEventList] = useState([]);
  const [eventPastList, setEventPastList] = useState([]);
  const [eventFutureList, setEventFutureList] = useState([]);

  const [eventJoinList, setEventJoinList] = useState([]);
  const [eventJoinPastList, setEventJoinPastList] = useState([]);
  const [eventJoinFutureList, setEventJoinFutureList] = useState([]);

  const [eventPlanList, setEventPlanList] = useState([]);
  const [eventPlanPastList, setEventPlanPastList] = useState<any[]>([]);
  const [eventPlanFutureList, setEventPlanFutureList] = useState<any[]>([]);

  const [opendMenu, setOpendMenu] = useState("event");
  // const [isMemberOpen, setIsMemberOpen] = useState(false);

  const [changeCount, setChangeCount] = useState(0);
  const [errorText, setErrorText] = useState("");

  const [isParamReady, setIsParamReady] = useState(false);

  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  const parseAsMoment = (dateTimeStr: any) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };
  // ロケールを日本語に設定
  moment.locale("ja");

  useEffect(() => {
    console.log("uE_Book");
    const f = async () => {
      const querySnapshot = await getEvents();
      let lists: any = [];
      querySnapshot.forEach((doc: any) => {
        lists.push({
          id: doc.id,
          eki: doc.data().eki,
          gatheringPlace: doc.data().gatheringPlace,
          ParticipantsImg: doc.data().ParticipantsImg,
          title: doc.data().title,
          eventDate: doc.data().eventDate,
          priority: doc.data().priority,
          isActive: true,
          disabled: false,
        });
      });
      setEventList(lists);

      // const tomorrowdate = new Date();
      // tomorrowdate.setDate(tomorrowdate.getDate() + 1);
      const oneDayAgo = new Date();
      oneDayAgo.setDate(oneDayAgo.getDate() - 1);

      const listPast: any = [];
      const listFuture: any = [];

      lists.forEach((event: any) => {
        if (event.eventDate) {
          const eventDate = event.eventDate.toDate();
          // 日付を文字列に変換
          const eventDateString = parseAsMoment(eventDate)
            .format("M/D(ddd) HH:mm")
            .toString();

          // eventオブジェクトに文字列版の日付を追加
          const updatedEvent = {
            ...event,
            eventDateString, // 文字列の日付を追加
          };

          if (eventDate >= oneDayAgo) {
            listFuture.push(updatedEvent);
          } else {
            listPast.push(updatedEvent);
          }
        }
      });

      // 過去イベントを日付の降順（新しい順）にソート
      listPast.sort(
        (a: any, b: any) => b.eventDate.toDate() - a.eventDate.toDate()
      );

      // 未来イベントを日付の昇順（古い順）にソート
      listFuture.sort(
        (a: any, b: any) => a.eventDate.toDate() - b.eventDate.toDate()
      );

      setEventPastList(listPast);
      setEventFutureList(listFuture);

      const usercheck = onAuthStateChanged(getAuth(otherApp), async (user) => {
        // console.log("通過");
        // console.log(user)
        if (user) {
          //------------------------------------------------------------
          //JoinするイベントUserPrivateBooksをlists2で処理
          //------------------------------------------------------------
          const querySnapshot2 = await getUserPrivateBooks(user.uid);

          // ★追加: futureList と pastList を初期化
          let futureList: any = []; // ★追加
          let pastList: any = []; // ★追加
          let errorList: any = []; // ★追加
          const tomorrowdate = new Date();
          tomorrowdate.setDate(tomorrowdate.getDate() + 1);

          //ログイン中のアカウント
          let lists2: any = [];
          querySnapshot2.forEach((doc: any) => {
            const eventData = doc.data();
            // const eventDate = eventData.eventDate.toDate(); // Firestore の日付を変換

            const event = {
              id: doc.data().id,
              title: doc.data().title,
              isDelete: doc.data().isDelete,
            };

            lists2.push(event);
          });

          const userJoinedEvents = lists2
            .map((joinedEvent: any) => {
              // lists から該当イベントを探す
              const matchedEvent = lists.find(
                (event: any) => event.id === joinedEvent.id
              );

              if (matchedEvent) {
                const eventDateString = matchedEvent.eventDate
                  ? parseAsMoment(matchedEvent.eventDate.toDate())
                      .format("M/D(ddd) HH:mm")
                      .toString()
                  : "日付不明";

                // matchedEvent と joinedEvent をマージして詳細情報を追加
                return {
                  ...matchedEvent, // lists から取得したイベント情報
                  ...joinedEvent, // lists2 から取得した参加情報
                  eventDateString: eventDateString,
                  isDelete: false,
                };
              } else {
                //
                return {
                  ...joinedEvent, // lists2 の情報をそのまま利用
                  eventDate: "未設定",
                  eventDateString: "中止", // デフォルト値を設定
                  title: joinedEvent.title || "タイトル未定",
                  location: joinedEvent.location || "中止",
                  gatheringPlace: {
                    imageURL:
                      "https://firebasestorage.googleapis.com/v0/b/boukenguild.appspot.com/o/YamanoteRoute%2FIVPVkrAByzXCmvrELfHF%2FGroupPhoto%2Fuserupload%2F1737406870158?alt=media&token=f7cd627d-7899-471e-aa95-a5a88927cb1f",
                  },
                  participants: joinedEvent.ParticipantsImg || [],
                  eki: "中止",
                  isDelete: true,
                };
              }
            })
            .filter((event: any) => event !== null); // null の要素を除外

          setEventJoinList(userJoinedEvents);

          userJoinedEvents.forEach((event: any) => {
            if (event.eventDate && !(event.eventDate == "未設定")) {
              const eventDate = event.eventDate.toDate(); // FirestoreのTimestampをDate型に変換

              if (eventDate >= oneDayAgo) {
                futureList.push(event);
              } else {
                pastList.push(event);
              }
            } else {
              errorList.push(event);
            }
          });

          // ソート処理
          pastList.sort(
            (a: any, b: any) => b.eventDate.toDate() - a.eventDate.toDate()
          ); // 過去イベントを降順（新しい順）にソート
          futureList.sort(
            (a: any, b: any) => a.eventDate.toDate() - b.eventDate.toDate()
          ); // 未来イベントを昇順（古い順）にソート

          // futureList に errorList を末尾に追加
          futureList = futureList.concat(errorList);

          // 結果を状態にセット
          setEventJoinPastList(pastList);
          setEventJoinFutureList(futureList);

          //------------------------------------------------------------
          //ここからPlan list3で処理
          //------------------------------------------------------------

          // ユーザーのプライベートプランを取得
          const querySnapshot3 = await getUserPrivatePlan(user.uid);
          let lists3: any = [];

          // 取得したデータをリストに変換
          querySnapshot3.forEach((doc: any) => {
            // 該当イベントはキャンセルされている
            lists3.push({
              id: doc.data().id, // イベントID
              title: doc.data().title, // イベントタイトル
            });
          });

          // イベントプランのリストを状態にセット
          setEventPlanList(lists3);

          // lists3 を lists とマージ
          const userPlanEvents = lists3.map((planEvent: any) => {
            // lists 内の対応するイベントを検索
            const matchedEvent = lists.find(
              (event: any) => event.id === planEvent.id
            );

            if (matchedEvent) {
              // マッチしたイベントの日付を文字列に変換
              const eventDateString = matchedEvent.eventDate
                ? parseAsMoment(matchedEvent.eventDate.toDate())
                    .format("M/D(ddd) HH:mm")
                    .toString()
                : "日付不明";

              // matchedEvent と planEvent をマージして返す
              return {
                ...matchedEvent,
                ...planEvent,
                eventDateString,
              };
            }

            // マッチしない場合はデフォルト値を設定して残す
            return {
              ...planEvent,
              eventDate: "未設定", // デフォルト値: 日付未設定
              eventDateString: "未設定", // 表示用のデフォルト値
              location: "未設定", // デフォルト値: 場所未設定
              gatheringPlace: {
                imageURL:
                  "https://firebasestorage.googleapis.com/v0/b/boukenguild.appspot.com/o/default-placeholder-image.png", // デフォルト画像URL
              },
              participants: [], // デフォルト値: 参加者なし
            };
          });

          // 今日の日付を基準に未来と過去に分類
          const planFutureList: any[] = []; // 未来のイベントリスト
          const planPastList: any[] = []; // 過去のイベントリスト

          userPlanEvents.forEach((event: any) => {
            if (event.eventDate && event.eventDate !== "未設定") {
              // Firestore の Timestamp を Date に変換
              const eventDate = event.eventDate.toDate();

              // 基準日と比較して未来か過去に分類
              if (eventDate >= oneDayAgo) {
                planFutureList.push(event); // 未来イベントリストに追加
              } else {
                planPastList.push(event); // 過去イベントリストに追加
              }
            } else {
              planPastList.push(event); // 未設定の場合は過去イベント扱い
            }
          });

          // ソート処理
          planPastList.sort(
            (a: any, b: any) => b.eventDate?.toDate() - a.eventDate?.toDate() // 過去イベントを降順にソート
          );
          planFutureList.sort(
            (a: any, b: any) => a.eventDate?.toDate() - b.eventDate?.toDate() // 未来イベントを昇順にソート
          );

          // 状態にセット
          setEventPlanPastList(planPastList);
          setEventPlanFutureList(planFutureList);
        }
      });
      setIsParamReady(true);
      setLodingmodalopen(false);

      return () => usercheck();
    };
    f();
  }, [changeCount]);

  useEffect(() => {
    setOpendMenu(opendmenu ?? "event");
  }, [opendmenu]);

  const incrementChangeCount = () => {
    setChangeCount(changeCount + 1);
  };

  // 参加キャンセル時に呼ばれる
  const cancelUser = async (event_id: string) => {
    const auth = getAuth(otherApp);
    const user = auth.currentUser;
    if (user) {
      await deleteEventParticipants(event_id, user.uid);
      await deleteUserPrivateBook(user.uid, event_id);
      incrementChangeCount();
    } else {
      // console.log("ログインしていない");
    }
  };

  //参加時に呼ばれる
  const bookUser = async (
    event_id: string,
    event_title: string,
    event_eventDate: Date
  ) => {
    const auth = getAuth(otherApp);
    const user = auth.currentUser;
    // console.log("koko");
    // console.log(event_eventDate);
    if (user) {
      let doc = await getUserPublic(user.uid);
      if (typeof doc.data() !== "undefined") {
        if (typeof doc.data().name !== "undefined") {
          await setEventParticipants(event_id, user.uid, {
            uid: user.uid,
            name: doc.data().name,
          });
          await setUserPrivateBook(user.uid, event_id, {
            id: event_id,
            title: event_title,
            eventDate: event_eventDate,
          });
          incrementChangeCount();
        } else {
          console.log("name undfined");
          setErrorText(
            "ユーザに名前が設定されていないようです。プロフィール欄で設定してください。"
          );
        }
      } else {
        console.log("data undfined");
        setErrorText(
          "ユーザに名前が設定されていないようです。プロフィール欄で設定してください。"
        );
      }
    } else {
      // console.log("ログインしていない");
      // navigate("/Signin/");
      setOpendMenu("signin");
    }
  };

  const centerStyle = {
    // display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    height: "100vh", //画面の高さいっぱいに表示する場合
  };

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };

  // BookView に渡すプロパティを useMemo でまとめる (getter を活用)
  const bookDatas = useMemo(
    () => ({
      get opendMenu() {
        return opendMenu;
      },
      incrementChangeCount,
      get eventPastList() {
        return eventPastList;
      },
      get eventFutureList() {
        return eventFutureList;
      },
      cancelUser,
      bookUser,
      get errorText() {
        return errorText;
      },
      get eventJoinList() {
        return eventJoinList;
      },
      get eventJoinPastList() {
        return eventJoinPastList;
      },
      get eventJoinFutureList() {
        return eventJoinFutureList;
      },
      get eventPlanList() {
        return eventPlanList;
      },
      get eventPlanPastList() {
        return eventPlanPastList;
      },
      get eventPlanFutureList() {
        return eventPlanFutureList;
      },
      // 状態変更用の関数は直接渡す
      setOpendMenu,
    }),
    [
      opendMenu,
      incrementChangeCount,
      eventPastList,
      eventFutureList,
      cancelUser,
      bookUser,
      errorText,
      eventJoinList,
      eventJoinPastList,
      eventJoinFutureList,
      eventPlanList,
      eventPlanPastList,
      eventPlanFutureList,
      setOpendMenu, // 状態変更用の関数も依存配列に含める
    ]
  );

  return (
    <div style={centerStyle}>
      {isParamReady ? (
        <>
          <Navbar datas={datas} />

          <div
            style={
              datas.styletext.windowWidth > 1200
                ? styles1.container
                : styles2.container
            }
          >
            <BookView datas={datas} bookDatas={bookDatas} />
          </div>

          <Footer datas={datas} />
        </>
      ) : (
        <LodingBook datas={datas} />
      )}
      <LodingModal open={lodingmodalopen} />
    </div>
  );
};

export default Book;
