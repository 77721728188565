import { Route, BrowserRouter, Routes } from "react-router-dom";
import Top from "./components/Top/Top";
import Shizigatya from "./components/views/Shizigatya/Shizigatya";
import Gatyaresult from "./components/views/Gatyaresult/Gatyaresult";
import Tsukaikata from "./components/views/Tsukaikata/Tsukaikata";
import Developer from "./components/views/Developer/Developer";
import Book from "./components/views/Book/Book";
import Userpage from "./components/views/Userpage/Userpage";
import Event from "./components/views/Event/Event";
import Record from "./components/views/Record/Record";
import CreateEvent from "./components/views/CreateEvent/CreateEvent";
import EditEvent from "./components/views/EditEvent/EditEvent";
//import Subscription from "./components/Point/Subscription/Subscription";
import Payment from "./components/Point/Payment/Payment";
import StripeSuccess from "./components/Point/StripeBack/StripeSuccess";
import StripeError from "./components/Point/StripeBack/StripeError";

type Props = {
  // bgColor: any;
  // styletext: any;
  datas: any;
  // setDatas: any;
  // routeList: any;
  // userInfo: any;
};

const Router: React.VFC<Props> = ({
  // bgColor,
  // styletext,
  datas,
}: // setDatas,
// routeList,
// userInfo,
Props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Book datas={datas} />} />
        <Route path="/Top" element={<Top datas={datas} />} />
        {/* <Route path="/ekisentaku" element={<Ekisentaku datas={datas} />} /> */}
        <Route
          path="/shizigatya/:eventid/:ekiid"
          element={<Shizigatya datas={datas} />}
        />
        <Route
          path="/gatyaresult/:eventid/:ekiid/:id1/:id2/:id3"
          element={<Gatyaresult datas={datas} />}
        />

        <Route path="/tsukaikata" element={<Tsukaikata datas={datas} />} />
        <Route path="/DeveloperView" element={<Developer datas={datas} />} />
        <Route path="/Book" element={<Book datas={datas} />} />
        <Route path="/Book/:opendmenu" element={<Book datas={datas} />} />

        <Route path="/Userpage" element={<Userpage datas={datas} />} />
        <Route path="/CreateEvent" element={<CreateEvent datas={datas} />} />
        <Route path="/Event/:eventid/:menu" element={<Event datas={datas} />} />
        <Route path="/Event/:eventid" element={<Event datas={datas} />} />
        <Route path="/Record/:recordid" element={<Record datas={datas} />} />

        <Route
          path="/EditEvent/:eventid"
          element={<EditEvent datas={datas} />}
        />

        <Route path="/Payment/:paytypeid" element={<Payment datas={datas} />} />

        <Route
          path="/StripeSuccess"
          element={<StripeSuccess datas={datas} />}
        />
        <Route path="/StripeError" element={<StripeError datas={datas} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
