import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  Box,
  Stack,
} from "@mui/material";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import PlaceIcon from "@mui/icons-material/Place";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Datas, EventDatas } from "../../../common/types";
import {
  deleteEventRoutes,
  updateEventRouteImage,
  addEventRouteImage,
  addGroupPhotoImage,
  updateGroupPhotoImage,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";
import {
  addCommandPicture,
  addYamanoteRouteEventPicture,
} from "../../../common/firebaseHelper/FirebaseTransactionHelper";

import ImageSetting from "../../../common/lib/ImageSetting";

import ShizigatyaEventView from "./ShizigatyaEventView";
import GatyaresultEventView from "./GatyaresultEventView";

// import ThumbUpIcon from "@mui/icons-material/ThumbUp"; // グッドアイコン
// import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral"; // 微妙アイコン
// import ThumbDownIcon from "@mui/icons-material/ThumbDown"; // 残念アイコン
import DeleteIcon from "@mui/icons-material/Delete"; // 削除アイコン

type Props = {
  datas: Datas;
  eventDatas: EventDatas;
};

const MenuEventRoute: React.VFC<Props> = ({ datas, eventDatas }: Props) => {
  const navigate = useNavigate();

  const [showGatya, setshowGatya] = useState<boolean>(false);
  const [imageurls, setImageurls] = useState<{ id: string; url: string }[][]>(
    []
  );

  const [groupPhotourls, setGroupPhotourls] = useState<
    { id: string; url: string }[]
  >([]);

  useEffect(() => {
    if (eventDatas.EventRoute) {
      const updatedImageUrls = eventDatas.EventRoute.map((route) =>
        Array.isArray(route?.Images)
          ? route.Images.map((image: any) => ({
              id: image?.id || "",
              url: image?.imageURL || "",
            }))
          : []
      );
      setImageurls(updatedImageUrls);
    } else {
      setImageurls([]);
    }

    if (eventDatas.GroupPhoto) {
      const transformedGroupPhotoUrls = eventDatas.GroupPhoto.map(
        (photo: any) => ({
          id: photo.id,
          url: photo.imageURL, // 必要に応じて photo.url を取得するロジックをここに書く
        })
      );
      setGroupPhotourls(transformedGroupPhotoUrls);
    }

    setshowGatya(false);
  }, [eventDatas?.EventRoute]);

  async function setimagefunc(
    downloadURL: string,
    eventDataId: string,
    routeId: string,
    commandId: string,
    commandIndex: number
  ): Promise<boolean> {
    const EventRouteImageId = await addEventRouteImage(eventDataId, routeId, {
      imageURL: downloadURL,
      user: datas.userInfo.userUid,
    });
    addCommandPicture(eventDatas.eventData.ekiID, commandId, {
      imageURL: downloadURL,
      user: datas.userInfo.userUid,
      createDate: new Date(),
      eventID: eventDataId,
    });

    //表示データ更新
    const updatedImageUrls = [...imageurls];

    updatedImageUrls[commandIndex] = [
      ...updatedImageUrls[commandIndex], // 現在の配列を展開
      { id: EventRouteImageId, url: downloadURL }, // 末尾に新しいデータを追加
    ];

    setImageurls(updatedImageUrls);

    return true;
  }

  async function updateimagefunc(
    downloadURL: string,
    eventDataId: string,
    routeId: string,
    EventRouteImageId: string,
    commandId: string,
    commandIndex: number,
    imageIndex: number
  ): Promise<boolean> {
    updateEventRouteImage(eventDataId, routeId, EventRouteImageId, {
      imageURL: downloadURL,
      user: datas.userInfo.userUid,
    });
    addCommandPicture(eventDatas.eventData.ekiID, commandId, {
      imageURL: downloadURL,
      user: datas.userInfo.userUid,
      updateDate: new Date(),
      eventID: eventDataId,
    });

    //表示データ更新
    const updatedImageUrls = [...imageurls];
    updatedImageUrls[commandIndex][imageIndex].url = downloadURL;
    setImageurls(updatedImageUrls);

    return true;
  }

  //ここからグループフォト-------------

  async function setGroupPhotofunc(
    downloadURL: string,
    eventDataId: string
  ): Promise<boolean> {
    const groupPhotoImageId = await addGroupPhotoImage(eventDataId, {
      imageURL: downloadURL,
      user: datas.userInfo.userUid,
    });
    addYamanoteRouteEventPicture(eventDatas.eventData.ekiID, {
      imageURL: downloadURL,
      user: datas.userInfo.userUid,
      createDate: new Date(),
      eventID: eventDataId,
    });

    //表示データ更新
    const updatedImageUrls = [
      ...groupPhotourls, // 現在の配列を展開
      { id: groupPhotoImageId, url: downloadURL }, // 末尾に新しいデータを追加
    ];
    setGroupPhotourls(updatedImageUrls);

    return true;
  }

  async function updateGroupPhotofunc(
    downloadURL: string,
    eventDataId: string,
    groupPhotoId: string,
    imageIndex: number
  ): Promise<boolean> {
    updateGroupPhotoImage(eventDataId, groupPhotoId, {
      imageURL: downloadURL,
      user: datas.userInfo.userUid,
    });
    addYamanoteRouteEventPicture(eventDatas.eventData.ekiID, {
      imageURL: downloadURL,
      user: datas.userInfo.userUid,
      createDate: new Date(),
      eventID: eventDataId,
    });
    //表示データ更新
    const updatedImageUrls = groupPhotourls;
    updatedImageUrls[imageIndex].url = downloadURL;
    setGroupPhotourls(updatedImageUrls);

    return true;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        {/* ヘッダー情報 */}
        <Typography variant="h5" gutterBottom>
          {eventDatas.eventData.eki} 散歩イベント
        </Typography>

        <Divider sx={{ my: 2 }} />

        {/* 集合場所セクション */}
        <Box sx={{ my: 4, textAlign: "center" }}>
          <Typography variant="h6" gutterBottom>
            集合場所
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 2,
              border: "1px solid #ddd",
              borderRadius: 2,
              backgroundColor: "#f9f9f9",
              width: "100%", // 親要素の幅を100%に設定
              boxSizing: "border-box", // ボーダー幅考慮
              overflow: "hidden", // はみ出し防止
            }}
          >
            <PlaceIcon
              fontSize="large"
              style={{ color: datas.bgColor.back04 }}
            />
            <Typography variant="body1" sx={{ mt: 1 }}>
              {eventDatas.eventData.gatheringPlace.text}
              <br />
              {eventDatas.eventData.eventDateString}
            </Typography>
            <img
              src={eventDatas.eventData.gatheringPlace.imageURL}
              alt="集合場所"
              style={{
                maxHeight: 200,
                marginTop: 10,
                maxWidth: "100%", // 親要素の幅に収める
                height: "auto", // 縦横比を維持
                objectFit: "contain", // はみ出し防止設定
              }}
            />
          </Box>
        </Box>

        {/* 散歩ルートのリスト */}
        <Typography variant="h6" gutterBottom>
          散歩ルート
        </Typography>
        <List>
          {eventDatas.EventRoute.map((item: any, index: any) => (
            <React.Fragment key={item.id}>
              {/* 各ポイントのカード */}
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 2,
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  backgroundColor: "#fff",
                  mb: 2,
                  width: "100%", // 親要素の幅を100%に
                  boxSizing: "border-box", // ボーダー幅を含む
                  overflow: "hidden", // はみ出し防止
                }}
              >
                <DirectionsWalkIcon style={{ color: datas.bgColor.back04 }} />
                <ListItemText
                  primary={`#${item.num} ${item.text}`}
                  // secondary={`Command ID: ${item.commandId}`}
                  sx={{ textAlign: "center" }}
                />
                {/* ImageSetting を枠内に収める */}
                <Box
                  sx={{
                    mt: 2,
                    width: "100%",
                    border: "1px solid #ddd",
                    borderRadius: 2,
                    p: 2,
                    backgroundColor: "#f9f9f9",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1,
                  }}
                >
                  {imageurls[index]?.map((imageurl, imgIndex) => (
                    <ImageSetting
                      key={imgIndex}
                      imageurl={imageurl.url}
                      setimagefunc={(downloadURL: string) =>
                        updateimagefunc(
                          downloadURL,
                          eventDatas.eventData.id,
                          item.id,
                          imageurl.id,
                          item.commandId,
                          index,
                          imgIndex
                        )
                      }
                      strageurl={`YamanoteRoute/${eventDatas.eventData.ekiID}/Command/${item.id}/userupload/`}
                    />
                  ))}

                  {/* 追加用の空の箱 */}
                  <ImageSetting
                    imageurl={""} // 初期値は空
                    setimagefunc={(downloadURL: string) =>
                      setimagefunc(
                        downloadURL,
                        eventDatas.eventData.id,
                        item.id,
                        item.commandId,
                        index
                      )
                    }
                    strageurl={`YamanoteRoute/${eventDatas.eventData.ekiID}/Command/${item.id}/userupload/`}
                  />
                </Box>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    onClick={() => {
                      window.open(
                        `https://maps.google.com/maps?q=` + item.text
                      );
                    }}
                  >
                    map検索
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => {
                      window.open(
                        `https://www.google.com/search?q=` + item.text
                      );
                    }}
                  >
                    google検索
                  </Button>
                  {/* <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: datas.bgColor.back04, // 緑色
                      color: datas.bgColor.text05.fontColor, // テキスト色
                      "&:hover": { backgroundColor: datas.bgColor.back06 }, // ホバー時の色
                    }}
                    onClick={() => {}}
                  >
                    <ThumbUpIcon />
                  </Button>

                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "#ff9800", // オレンジ色
                      color: "#fff",
                      "&:hover": { backgroundColor: "#fb8c00" },
                    }}
                    onClick={() => {}}
                  >
                    <ThumbDownIcon />
                  </Button> */}
                  {datas.userInfo.adminFlag ? (
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      startIcon={<DeleteIcon />} // 削除アイコン追加
                      onClick={() => {
                        deleteEventRoutes(eventDatas.eventData.id, item.id);
                        eventDatas.incrementChangeCount();
                        // navigate("/Book/join");
                      }}
                    >
                      削除
                    </Button>
                  ) : (
                    <></>
                  )}
                </Stack>
              </ListItem>

              {/* 矢印を表示 */}
              {index < eventDatas.EventRoute.length - 1 && (
                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                  <ArrowDownwardIcon color="disabled" />
                </Box>
              )}
            </React.Fragment>
          ))}

          {/* ルート追加ボタン */}
          <Box sx={{ my: 4, textAlign: "center" }}>
            {showGatya ? (
              <>
                {" "}
                {eventDatas.Gatyaresult.choosing ? (
                  <ShizigatyaEventView datas={datas} eventDatas={eventDatas} />
                ) : (
                  <GatyaresultEventView datas={datas} eventDatas={eventDatas} />
                )}
              </>
            ) : (
              <>
                {" "}
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    fontSize: 18,
                    backgroundColor: datas.bgColor.back04, // 緑色
                    color: datas.bgColor.text05.fontColor, // テキスト色
                    "&:hover": { backgroundColor: datas.bgColor.back06 }, // ホバー時の色
                  }}
                  onClick={() => {
                    setshowGatya(true);
                    eventDatas.setGatyaresult({
                      choosing: true,
                      id1: "",
                      id2: "",
                      id3: "",
                    });
                  }}
                >
                  ルート追加（ガチャる）
                </Button>
              </>
            )}
          </Box>

          {/* 集合写真 */}
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 2,
              border: "1px solid #ddd",
              borderRadius: 2,
              backgroundColor: "#fff",
              mb: 2,
              width: "100%", // 親要素の幅を100%に
              boxSizing: "border-box", // ボーダー幅を含む
              overflow: "hidden", // はみ出し防止
            }}
          >
            集合写真を登録しよう
            <Box
              sx={{
                mt: 2,
                width: "100%",
                border: "1px solid #ddd",
                borderRadius: 2,
                p: 2,
                backgroundColor: "#f9f9f9",
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              {groupPhotourls?.map((imageurl, imgIndex) => (
                <Box sx={{ my: 4, textAlign: "center" }}>
                  <ImageSetting
                    key={imgIndex}
                    imageurl={imageurl.url}
                    setimagefunc={(downloadURL: string) =>
                      updateGroupPhotofunc(
                        downloadURL,
                        eventDatas.eventData.id,
                        imageurl.id,
                        imgIndex
                      )
                    }
                    strageurl={`YamanoteRoute/${eventDatas.eventData.ekiID}/GroupPhoto/userupload/`}
                  />
                </Box>
              ))}
              {/* 追加用の空の箱 */}
              <Box
                sx={{
                  my: 4,
                  textAlign: "center",
                  display: "flex", // Flexboxで整列
                  justifyContent: "center", // 水平方向中央揃え
                  alignItems: "center", // 垂直方向中央揃え
                  flexDirection: "column", // 必要なら子要素を縦並びに
                  width: "100px", // 他の画像要素と一致させる
                  height: "100px", // 他の画像要素と一致させる
                }}
              >
                <ImageSetting
                  imageurl={""} // 初期値は空
                  setimagefunc={(downloadURL: string) =>
                    setGroupPhotofunc(downloadURL, eventDatas.eventData.id)
                  }
                  strageurl={`YamanoteRoute/${eventDatas.eventData.ekiID}/GroupPhoto/userupload/`}
                />
              </Box>
            </Box>
          </ListItem>

          <Box sx={{ my: 4, textAlign: "center" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                fontSize: 18,
                backgroundColor: datas.bgColor.back04, // 緑色
                color: datas.bgColor.text05.fontColor, // テキスト色
                "&:hover": { backgroundColor: datas.bgColor.back06 }, // ホバー時の色
              }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" }); // 上にスクロール
                eventDatas.setOpendMenu("afterBook");
              }}
            >
              戻る
            </Button>
          </Box>
        </List>
      </Paper>
    </Container>
  );
};

export default MenuEventRoute;
