const cellStyle = {
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "10px",
  paddingBottom: "10px",
};
const cellStyleBold = {
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontWeight: "bold",
  color: "#B31B00",
};

export const pointtableTypeA = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>消費</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyleBold}>散歩参加</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>100pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>散歩生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
    </tbody>
  </table>
);
