import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas } from "../../../common/types";

import { Container, Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import StageBlock from "./StageBlock";

import { getAuth, signOut } from "firebase/auth";
import { otherApp } from "../../../common/firebaseHelper/firebase";
type Props = {
  datas: Datas;
  // bgColor: any;
  setIsSwipeOpen: any;
  // userInfo: any;
  buttonHeight: any;
  textMarginTop: any;
  // setOpendMenu: any;
};

const Loadmap: React.VFC<Props> = ({
  datas,
  // bgColor,
  setIsSwipeOpen,
  // isLogin,
  // avatar,
  // userInfo,
  buttonHeight,
  textMarginTop,
}: // setOpendMenu,
Props) => {
  const navigate = useNavigate();
  const [titleTextLogin, setTitleTextLogin] = useState(false);
  const [titleTextSubscribed, setTitleTextSubscribed] = useState(false);

  const handleSignout = () => {
    signOut(getAuth(otherApp))
      .then(() => {
        datas.initUserIsNotLogin();
        window.scrollTo(0, 0);
        navigate("/Book");
      })
      .catch((error: any) => {
        console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
  };
  return (
    <>
      <div
        style={{
          backgroundColor: datas.bgColor.back04,
        }}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <Button
                  onClick={() => {
                    setIsSwipeOpen(false);
                  }}
                  style={{
                    width: `calc(calc(100% - 110px) / 3)`,
                    height: buttonHeight,
                    borderRadius: "0px",
                    // color: props.bgColor.text08.fontColor,
                    fontWeight: "bold",
                    padding: "0px",
                  }}
                >
                  <ArrowForwardIosIcon
                    style={{
                      fill: datas.bgColor.back03,
                    }}
                  />
                </Button>
              </td>

              {datas.userInfo.isLogin ? (
                <td>
                  <Button
                    // themecolor={"white2"}
                    onClick={() => {
                      navigate("/Book/profile");
                      // setOpendMenu("profile");
                      setIsSwipeOpen(false);
                    }}
                    style={{
                      borderRadius: "0px",
                      paddingLeft: "0px",
                      //   color: props.datas.bgColor.text04.fontColor,
                      // fontSize: "17px",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr>
                          <td align="center">
                            <img
                              src={datas.userInfo.avatar.img}
                              alt="img"
                              style={{
                                width: "50px" /*任意のサイズ*/,
                                height: "50px",
                                borderRadius: "50% 50%",
                                // border: "solid 1px skyblue",
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            ></img>
                          </td>
                          <td
                            align="center"
                            style={{ color: "white", fontSize: "14px" }}
                          >
                            {datas.userInfo.avatar.name.length > 9
                              ? `${datas.userInfo.avatar.name.substring(
                                  0,
                                  8
                                )}...`
                              : datas.userInfo.avatar.name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Button>
                </td>
              ) : (
                <>
                  <td>
                    <Button
                      onClick={() => {
                        navigate("/Book/signup");
                        // setOpendMenu("signup");
                        setIsSwipeOpen(false);
                      }}
                      style={{
                        width: `calc(calc(100% - 110px) / 4)`,
                        height: buttonHeight,
                        borderRadius: "0px",
                        color: datas.bgColor.back03,
                        fontWeight: "bold",
                      }}
                    >
                      <PersonAddAltIcon
                        style={{
                          fill: datas.bgColor.back03,
                          marginTop: "-10px",
                        }}
                      />
                      <div style={{ position: "absolute" }}>
                        <div
                          style={{
                            height: textMarginTop,
                            minWidth: "75px",
                          }}
                        ></div>
                        登録
                      </div>
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        navigate("/Book/signin");
                        // setOpendMenu("signin");
                        setIsSwipeOpen(false);
                      }}
                      style={{
                        width: `calc(calc(100% - 110px) / 4)`,
                        height: buttonHeight,
                        borderRadius: "0px",
                        color: datas.bgColor.back03,
                        fontWeight: "bold",
                      }}
                    >
                      <PersonIcon
                        style={{
                          fill: datas.bgColor.back03,
                          marginTop: "-10px",
                        }}
                      />
                      <div style={{ position: "absolute" }}>
                        <div
                          style={{
                            height: textMarginTop,
                            minWidth: "75px",
                          }}
                        ></div>
                        ログイン
                      </div>
                    </Button>
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ backgroundColor: datas.bgColor.back01 }}>
        <Container maxWidth="lg">
          <StageBlock
            bgColor={datas.bgColor}
            title={"基本機能"}
            hint={false}
            menu={[
              {
                text: "Boukenguildとは？",
                excute: () => {
                  navigate("/Tsukaikata");
                  setIsSwipeOpen(false);
                },
              },
            ]}
            both={[]}
            subscribe={[
              {
                text: "存在しない選択肢",
                excute: () => {},
              },
            ]}
            isusable={true}
          />
          <StageBlock
            bgColor={datas.bgColor}
            title={"無料機能"}
            hint={titleTextLogin}
            menu={[
              {
                text: "予約確認",
                excute: () => {
                  navigate("/Book/join");
                  // setOpendMenu("join");
                  setIsSwipeOpen(false);
                },
              },
              {
                text: "イベント作成",
                excute: () => {
                  navigate("/Book/plan");
                  // setOpendMenu("plan");
                  setIsSwipeOpen(false);
                },
              },
              {
                text: "アカウント情報",
                excute: () => {
                  navigate("/Book/profile");
                  // setOpendMenu("profile");
                  setIsSwipeOpen(false);
                },
              },

              {
                text: "サインアウト",
                excute: () => {
                  handleSignout(); //この中で画面遷移
                  setIsSwipeOpen(false);
                },
              },
            ]}
            both={[]}
            subscribe={[
              {
                text: "アカウント登録",
                excute: () => {
                  navigate("/Book/signup");
                  // setOpendMenu("signup");
                  setIsSwipeOpen(false);
                },
              },
              {
                text: "ログイン",
                excute: () => {
                  navigate("/Book/signin");
                  // setOpendMenu("signin");
                  setIsSwipeOpen(false);
                },
              },
            ]}
            isusable={datas.userInfo.isLogin}
          />

          {datas.userInfo.isLogin ? (
            <>
              <StageBlock
                bgColor={datas.bgColor}
                title={"有料機能"}
                hint={titleTextSubscribed}
                menu={[
                  {
                    text: "プラン解約・変更",
                    excute: () => {
                      navigate("/Payment/cancell");
                      setIsSwipeOpen(false);
                    },
                  },
                ]}
                both={[
                  {
                    text: "ポイント確認",
                    excute: () => {
                      navigate("/Payment/history");
                      setIsSwipeOpen(false);
                    },
                  },
                  {
                    text: "ポイント購入",
                    excute: () => {
                      navigate("/Payment/singlepurchase");
                      setIsSwipeOpen(false);
                    },
                  },
                ]}
                subscribe={[
                  {
                    text: "サブスクリプション登録",
                    excute: () => {
                      navigate("/Payment/subscription");
                      setIsSwipeOpen(false);
                    },
                  },
                ]}
                // isusable={userIsSubscribed}
                isusable={
                  datas.UserPaymentDatas.userIsStandard ||
                  datas.UserPaymentDatas.userIsPro
                }
              />
            </>
          ) : (
            <></>
          )}

          {datas.userInfo.adminFlag ? (
            <StageBlock
              bgColor={datas.bgColor}
              title={"Admin機能"}
              hint={titleTextSubscribed}
              menu={[
                {
                  text: "データ整理",
                  excute: () => {
                    navigate("/DeveloperView/");
                    setIsSwipeOpen(false);
                  },
                },
                {
                  text: "旧Top",
                  excute: () => {
                    navigate("/Top/");
                    setIsSwipeOpen(false);
                  },
                },
                {
                  text: "Record",
                  excute: () => {
                    navigate("/Record/vsbHCzSuMxbJkxxECgGZ");
                    setIsSwipeOpen(false);
                  },
                },
              ]}
              both={[]}
              subscribe={[]}
              isusable={datas.userInfo.adminFlag}
            />
          ) : (
            <></>
          )}
        </Container>
      </div>
    </>
  );
};
export default Loadmap;
