import React from "react";
import { Datas, EventDatas } from "../../../common/types";
type Props = {
  datas: Datas;
  eventDatas: EventDatas;
};

const MenuEventCreater: React.VFC<Props> = ({ datas, eventDatas }: Props) => {
  return <></>;
};
export default MenuEventCreater;
