import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { get3commands } from "../../../common/firebaseHelper/FirebaseTransactionHelper";
import { addEventRoute } from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";
import { useQRCode } from "next-qrcode";

import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
} from "@mui/material";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import GatyaresultModal from "../../Modal/GatyaresultModal/GatyaresultModal";

type Props = {
  datas: Datas;
};

const centerStyle = {
  // display: "flex",
  // flexDirection: "column" as const,
  // alignItems: "center",
  // justifyContent: "center",
  // minHeight: "100vh", //画面の高さいっぱいに表示する場合
  // width: "100%",
};
const centerStyle2 = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
};

const centerStyle3 = {
  // alignItems: "center",
  // justifyContent: "center",
  // width: "80%",
};

const GatyaresultView: React.FC<Props> = ({ datas }) => {
  let { eventid, ekiid, id1, id2, id3 } = useParams();
  const [showText, setShowText] = useState("回そう");
  const [selectCommand, setSelectCommand] = useState(false);
  const [selectCommandId, setSelectCommandId] = useState(false);
  const [randomCommands, setRandomCommands] = useState<any[]>([]);

  const [selectedGatyaresult, setSelectedGatyaresult] = useState({
    id: "",
    text1: "",
    text2: "",
    img: "",
  });

  const [gatyaresultmodalopen, setGatyaresultmodalopen] = useState(false);

  // const [isQROpen, setIsQROpen] = useState(false);

  const navigate = useNavigate();
  const { Canvas } = useQRCode();

  const colorset = ["#ffdddd", "#ddffdd", "#ddddff"];
  const colorset2 = ["#662222", "#226622", "#222266"];

  useEffect(() => {
    const f = async () => {
      const querySnapshot = await get3commands(ekiid, [id1, id2, id3]);
      let lists: any = [];
      querySnapshot.forEach((doc: any) => {
        const oneline = { id: doc.id, data: doc.data(), select: false };
        lists.push(oneline);
        // console.log(oneline);
      });
      setRandomCommands(lists);
    };
    f();
  }, []);

  //指令を更新する処理
  // const doChange = (dotext: string) => {
  //   const newMoveList = datas.moveList;
  //   newMoveList.push({
  //     num: datas.step.num,
  //     text: datas.place.text + " : " + dotext,
  //   });
  //   setDatas({
  //     place: datas.place,
  //     step: datas.step,
  //     moveList: newMoveList,
  //   });
  // };

  //指令を更新する処理
  const randomCommandsChange = (selectid: string) => {
    let lists: any = [];
    randomCommands.forEach((command: any) => {
      let oneline;
      if (command.id == selectid) {
        oneline = { id: command.id, data: command.data, select: true };
      } else {
        oneline = { id: command.id, data: command.data, select: false };
      }
      lists.push(oneline);
    });
    setRandomCommands(lists);
  };

  return (
    <div style={centerStyle}>
      <div>
        <br />
        <br />
        ● 1つ選んで見せあいましょう
        <br />
        <div>
          {randomCommands.map((command, i) => (
            <div key={i}>
              <Button
                // variant={command.select ? "contained" : "outlined"}
                style={{
                  width: "100%",
                  fontSize: 15,
                  backgroundColor: command.select ? colorset2[i] : colorset[i],
                  color: command.select ? "white" : "black",
                  padding:
                    "0px 60px" /* 上下10ピクセル、左右20ピクセルの余白 */,
                }}
                onClick={() => {
                  randomCommandsChange(command.id);
                  setSelectCommand(command.data.text);
                  setSelectCommandId(command.id);

                  setGatyaresultmodalopen(true);
                  setSelectedGatyaresult({
                    id: command.id,
                    text1: command.data.text,
                    text2: "",
                    img: command.data.imageURL,
                  });
                  //   selectCommand(command);
                  //navigate("/Sanponaiyou/" + ekiid + "/" + command.id); // ここでURLに遷移する
                }}
              >
                <br />
                {command.data.text}
                <br />
                {/* {command.data.openingtime instanceof Timestamp ? (
                    <>
                      {command.data.openingtime.toDate().toLocaleString("ja-JP", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                      -
                    </>
                  ) : (
                    <></>
                  )}
                  {command.data.closingtime instanceof Timestamp ? (
                    <>
                      {command.data.closingtime.toDate().toLocaleString("ja-JP", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </>
                  ) : (
                    <></>
                  )} */}
                <br />
                {/* <img src={command.data.imageURL}></img> */}
              </Button>
              <br />
            </div>
          ))}
        </div>
        <div style={{ width: "1000" }}>
          {selectCommand ? (
            <>
              <br />
              {selectCommand}
              <br />を
              <Button
                type="submit"
                onClick={() => {
                  window.open(
                    `https://maps.google.com/maps?q=` + selectCommand
                  );
                }}
              >
                map検索
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  window.open(
                    `https://www.google.com/search?q=` + selectCommand
                  );
                }}
              >
                google検索
              </Button>
              {/* <Button
                type="submit"
                onClick={() => {
                  console.log(selectCommandId);
                  addEventRoute(eventid, {
                    id: selectCommandId,
                    text: selectCommand,
                  });
                }}
              >
                ルート登録
              </Button> */}
              <br />
            </>
          ) : (
            <>
              <br />
              <div style={{ color: "white" }}>.</div>
              <Button style={{ color: "white" }}>.</Button>
              <br />
            </>
          )}
        </div>
        <br />
      </div>
      <div style={centerStyle3}>
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                style={{ textAlign: "center", width: "100%", fontSize: "20px" }}
              >
                メニュー
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div style={centerStyle2}>
                  <Link
                    href="#"
                    onClick={() => {
                      if (navigator.share) {
                        navigator
                          .share({
                            title: "シェアのタイトル",
                            text: "シェアするテキスト",
                            url: "https://example.com",
                          })
                          .then(() => console.log("シェアに成功しました"))
                          .catch((error) =>
                            console.error("シェアに失敗しました:", error)
                          );
                      } else {
                        console.log(
                          "Web シェア API はこのブラウザではサポートされていません"
                        );
                      }
                    }}
                    style={{
                      width: "100%",
                      fontSize: 20,
                      textDecoration: "underline", // 下線を追加してリンクのように見せる
                      color: "blue", // リンクのような色
                      display: "inline-block", // ブロック要素として表示
                      textAlign: "center", // 中央揃え
                    }}
                  >
                    ガチャ結果を共有
                  </Link>
                  <Canvas
                    text={
                      "https://boukenguild.com/Gatyaresult/" +
                      eventid +
                      "/" +
                      ekiid +
                      "/" +
                      id1 +
                      "/" +
                      id2 +
                      "/" +
                      id3
                    }
                    options={{
                      type: "image/jpeg",
                      quality: 0.3,
                      // level: "M",
                      margin: 3,
                      scale: 4,
                      width: 250,
                      color: {
                        dark: "#000000",
                        light: "#FFFFFF",
                      },
                    }}
                  />
                </div>

                <br />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {gatyaresultmodalopen ? (
        <GatyaresultModal
          open={gatyaresultmodalopen}
          setOpen={setGatyaresultmodalopen}
          selectedGatyaresult={selectedGatyaresult}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default GatyaresultView;
