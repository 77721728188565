import { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { selectedGatyaresult } from "../../../common/types";

type Props = {
  open: boolean;
  setOpen: any;
  selectedGatyaresult: selectedGatyaresult;
};

const GatyaresultModal: React.VFC<Props> = ({
  open,
  setOpen,
  selectedGatyaresult,
}: Props) => {
  // ガチャ結果の詳細表示
  const navigate = useNavigate();

  // モーダルを閉じる関数
  const handleCloseinside = () => {
    setOpen(false);
  };

  return (
    // モーダルの定義
    <Modal
      aria-labelledby="transition-modal-title" // モーダルのタイトル設定
      aria-describedby="transition-modal-description" // モーダルの説明設定
      //className={classes.modal} // モーダルのスタイル適用
      open={open} // モーダルの開閉状態をpropsから取得
      onClose={handleCloseinside} // モーダル外クリック時の処理
      closeAfterTransition // フェードアニメーション完了後にDOM要素を削除
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            width: { xs: "90%", sm: "70%", md: "50%" },
            maxHeight: "80vh",
            overflowY: "auto",
            p: 4,
            position: "relative", // 相対位置を設定
          }}
        >
          {/* 閉じるボタン */}
          <Button
            onClick={handleCloseinside}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              minWidth: "32px",
              minHeight: "32px",
              padding: "4px",
              borderRadius: "50%",
              backgroundColor: "#f5f5f5",
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
            }}
          >
            ✕
          </Button>

          {/* タイトル */}
          <Typography
            id="transition-modal-title"
            variant="h5"
            fontWeight="bold"
            mb={2}
          >
            {selectedGatyaresult.text1 || "ガチャ結果"}
          </Typography>

          {/* 画像 */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <img
              src={selectedGatyaresult.img}
              alt="ガチャ画像"
              style={{
                maxWidth: "100%",
                maxHeight: "300px",
                borderRadius: "10px",
              }}
            />
          </Box>

          {/* テキスト */}
          <Typography variant="body1" color="textSecondary" mb={2}>
            {selectedGatyaresult.text2}
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};
export default GatyaresultModal;
