import MenuEventBeforeBook from "./MenuEventBeforeBook";
import MenuEventAfterBook from "./MenuEventAfterBook";
import MenuEventCreater from "./MenuEventCreater";
import MenuEventRoute from "./MenuEventRoute";

import { Datas, EventDatas } from "../../../common/types";

type Props = {
  datas: Datas;
  eventDatas: EventDatas;
};

const EventView: React.VFC<Props> = ({ datas, eventDatas }: Props) => {
  return (
    <>
      {eventDatas.opendMenu === "creater" ? (
        <MenuEventCreater datas={datas} eventDatas={eventDatas} />
      ) : eventDatas.opendMenu === "beforeBook" ? (
        <MenuEventBeforeBook datas={datas} eventDatas={eventDatas} />
      ) : eventDatas.opendMenu === "afterBook" ? (
        <MenuEventAfterBook datas={datas} eventDatas={eventDatas} />
      ) : eventDatas.opendMenu === "eventRoute" ? (
        <MenuEventRoute datas={datas} eventDatas={eventDatas} />
      ) : (
        <div>ここは表示されないはず</div>
      )}
    </>
  );
};
export default EventView;
