import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import { Datas, Route, Command } from "../../../common/types";
import ImageSetting from "../../../common/lib/ImageSetting";

import { Button, TextField } from "@mui/material";

import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Timestamp } from "firebase/firestore"; // FirestoreのTimestamp型をインポート
import { getCommandImages } from "../../../common/firebaseHelper/FirebaseTransactionHelper";

type Props = {
  datas: Datas;

  text1: any;
  text2: any;
  eki_id: any;
  shizi_id: any;
  changing: any;
  setChanging: any;
  changeCount: number;
};

const DeveloperCommandImage: React.VFC<Props> = ({
  datas,

  text1,
  text2,
  eki_id,
  shizi_id,
  changing,
  setChanging,
  changeCount,
}: Props) => {
  const [pics, setPics] = useState([]);
  useEffect(() => {
    console.log("uE_DeveloperCommandImage");
    const f = async () => {
      if (eki_id && shizi_id) {
        getDataCommandImages(String(eki_id), String(shizi_id));
      } else {
        alert("エラーです");
      }
    };
    f();
  }, [changeCount]);

  //指令を取得する処理
  async function getDataCommandImages(
    id: string,
    id2: string
  ): Promise<boolean> {
    console.log(id, id2);
    const querySnapshot = await getCommandImages(id, id2);

    let lists: any = [];
    querySnapshot.forEach((doc: any) => {
      lists.push({
        id: doc.id,
        createDate: doc.data().createDate,
        user: doc.data().user,
        imageurl: doc.data().imageURL,
      });
    });
    console.log(lists);

    setPics(lists);

    return true;
  }

  return (
    <>
      {pics.map((item2: any, index: any) => (
        // <div key={index}>{item2.imageurl}</div>
        <img
          src={item2.imageurl}
          alt="Uploaded"
          //   style={{ maxHeight: "100px", maxWidth: "100px" }}
        />
      ))}
    </>
  );
};
export default DeveloperCommandImage;
