import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";

import {
  getData,
  getCommands,
} from "../../../common/firebaseHelper/FirebaseTransactionHelper";
import { useNavigate } from "react-router-dom";
import { Datas, EventDatas } from "../../../common/types";
import { useParams } from "react-router-dom";

import { Button } from "@mui/material";

type Props = {
  datas: Datas;
  eventDatas: EventDatas;
};
type command = {
  id: string;
  text: string;
};
const centerStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  justifyContent: "center",
  //   minHeight: "100vh", //画面の高さいっぱいに表示する場合
};

const ShizigatyaEventView: React.VFC<Props> = ({
  datas,
  eventDatas,
}: Props) => {
  //   let { eventid, ekiid } = useParams();
  const [start, setStart] = useState(false);
  const [ekiText, setEkiText] = useState("");
  const [index, setIndex] = useState(0);
  const [flag, setFlag] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [commandList, setCommandList] = useState<command[]>([]);
  // ランダムな3つの指令を保持するためのstateを追加
  const [randomCommands, setRandomCommands] = useState<command[]>([]);
  // 選択された指令を保持するためのstateを追加
  // const [selectedCommand, setSelectedCommand] = useState<command | null>(null);

  const navigate = useNavigate();

  // const rouletteContents: command[] = [
  //   // { id: "common1", text: "面白いものを見つける" },
  //   // { id: "common2", text: "景色に感想を言う" },
  // ];

  useEffect(() => {
    const f = async () => {
      const id: string = eventDatas.eventData.ekiID ?? "kdJ30lwneh3eGIVIF09J";
      const docRef = await getData(id);
      setEkiText(docRef.data().text);

      const querySnapshot = await getCommands(id);
      let lists: any = [];

      // 訪れた場所の commandId をリスト化
      const visitedCommandIds = eventDatas.EventRoute.map(
        (route: any) => route.commandId
      );

      querySnapshot.forEach((doc: any) => {
        const commandId = doc.id;
        if (!visitedCommandIds.includes(commandId)) {
          lists.push({ id: doc.id, text: doc.data().text });
        }
      });

      setCommandList(lists);
      startRoulette();
    };
    f();
  }, []);

  //ボタンの文言を変更する処理
  const startRoulette = useCallback(() => {
    setFlag(true);

    setStart(!start);
  }, [start]);

  // ルーレットを回す処理
  useEffect(() => {
    if (start) {
      const interval = setInterval(() => {
        setRandomCommands(() => {
          const randomIndexes: number[] = [];
          while (randomIndexes.length < 3) {
            const randomIndex = Math.floor(Math.random() * commandList.length);
            if (!randomIndexes.includes(randomIndex)) {
              randomIndexes.push(randomIndex);
            }
          }
          return randomIndexes.map((index) => commandList[index]);
        });
      }, 50); // ルーレットの中身を切り替える速度
      return () => clearInterval(interval);
    } else if (!start) {
      if (flag) {
        setButtonDisabled(true);
        const randomids: string[] = [];
        randomCommands.map((command, i) => {
          //console.log(command.id);
          randomids.push(command.id);
        });

        eventDatas.setGatyaresult({
          choosing: false,
          id1: randomids[0],
          id2: randomids[1],
          id3: randomids[2],
        });

        // navigate(
        //   "/Gatyaresult/" +
        //     eventDatas.eventData.id +
        //     "/" +
        //     eventDatas.eventData.ekiID +
        //     "/" +
        //     randomids[0] +
        //     "/" +
        //     randomids[1] +
        //     "/" +
        //     randomids[2] +
        //     "/"
        // ); // ここでURLに遷移する
      }
      return () => clearInterval(0);
    }
  }, [start]);

  return (
    <>
      <div style={centerStyle}>
        <br />
        {randomCommands.length == 0 ? (
          <>
            <div style={{ height: "210px" }}></div>
          </>
        ) : (
          <>
            {randomCommands.map((command: any, i: number) => (
              <div key={i} style={{ height: "70px" }}>
                {command.text}
                <br />
              </div>
            ))}
          </>
        )}

        {commandList.length <= 4 ? (
          <>
            <Button
              variant="outlined"
              disabled={true}
              style={{ width: "80%", fontSize: 20 }}
            >
              <br />
              読み込み中
              <br />
              <br />
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              onClick={startRoulette}
              disabled={buttonDisabled}
              style={{ width: "80%", fontSize: 20 }}
            >
              <br />
              {start ? "ストップ" : "スタート"}
              <br />
              <br />
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default ShizigatyaEventView;
