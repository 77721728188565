import { otherApp } from "./firebase";
import {
  doc,
  collection,
  getFirestore,
  runTransaction,
  query,
  getDocs,
  getDoc,
  orderBy,
  limit,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  documentId,
  where,
} from "firebase/firestore";

export async function getUserSecret(userId: any): Promise<any> {
  const db = getFirestore(otherApp);
  let q = doc(db, "usersecret", userId);
  let docRef = await getDoc(q);
  return docRef;
}

export async function setUserSecret(userId: any, data: any): Promise<any> {
  const db = getFirestore(otherApp);
  let q = doc(db, "usersecret", userId);
  const docRef = await setDoc(q, data);
  return docRef;
}

export async function updateUserSecret(userId: any, data: any): Promise<any> {
  const db = getFirestore(otherApp);
  let q = doc(db, "usersecret", userId);
  const docRef = await updateDoc(q, data);
  return docRef;
}

export async function createUserSecretHistory(id1: any, data: any) {
  const db = getFirestore(otherApp);
  const q = collection(db, "usersecret", id1, "history");
  const docRef = await addDoc(q, data);
  return docRef;
}

export async function getUserSecretPointCanUse(id1: string): Promise<number> {
  const db = getFirestore(otherApp); // Firestoreインスタンス取得
  let point = 0; // 初期ポイント
  let freepoint = 0; // 初期ポイント
  try {
    // ドキュメント参照を取得
    const docRef = doc(db, "usersecret", id1);
    const onedata = await getDoc(docRef); // ドキュメントを取得

    // データが存在するか確認
    if (onedata.exists()) {
      point = onedata.data()?.point ?? 0; // point が存在しない場合は 0 を返す
      freepoint = onedata.data()?.freepoint ?? 0; // point が存在しない場合は 0 を返す
    }

    console.log(point, freepoint);
  } catch (error) {
    console.error("Error fetching document:", error); // エラーログ
  }
  return point + freepoint; // 結果を返す
}

export async function pluspointUserSecret(userId: any, pluspoint: any) {
  const db = getFirestore(otherApp);
  try {
    // トランザクション処理
    const result = await runTransaction(db, async (transaction) => {
      // ドキュメント参照を取得
      const sfDocRef = doc(db, "usersecret", userId);
      const sfDoc = await transaction.get(sfDocRef); // ドキュメント取得

      // ドキュメントが存在するかチェック
      if (!sfDoc.exists()) {
        throw new Error("Document does not exist!");
      }

      // 現在のポイントを取得して更新
      const currentPoint = sfDoc.data()?.point ?? 0; // undefinedの場合は0
      const newPoint = currentPoint + pluspoint;

      // ポイント更新
      transaction.set(sfDocRef, { point: newPoint });

      // 新しいポイントを返す
      return newPoint;
    });

    // トランザクション結果を返す
    return result;
  } catch (e) {
    console.error("Transaction failed: ", e); // エラーログ
    return false; // エラー発生時はfalseを返す
  }
}
export async function minuspointUserSecret(
  userId: string, // 型をstringに指定
  minuspoint: number // 型をnumberに指定
): Promise<number | false> {
  const db = getFirestore(otherApp); // Firestoreインスタンス取得

  try {
    // トランザクション処理
    const result = await runTransaction(db, async (transaction) => {
      // ドキュメント参照取得
      const sfDocRef = doc(db, "usersecret", userId);
      const sfDoc = await transaction.get(sfDocRef); // ドキュメント取得

      // ドキュメントが存在するかチェック
      if (!sfDoc.exists()) {
        throw new Error("Document does not exist!");
      }

      // 現在のポイントを取得
      const currentPoint = sfDoc.data()?.point ?? 0; // デフォルト値は0

      // ポイントが足りるかチェック
      if (currentPoint < minuspoint) {
        console.log("Not enough points!");
        throw new Error("Not enough points!"); // エラーを投げる
      }

      // ポイントを減算
      const newPoint = currentPoint - minuspoint;

      // ポイント更新
      transaction.update(sfDocRef, { point: newPoint });

      // 新しいポイントを返す
      return newPoint;
    });

    // トランザクションの結果を返す
    return result;
  } catch (e) {
    console.error("Transaction failed: ", e); // エラーログ
    return false; // エラー発生時はfalseを返す
  }
}

export async function getUserSecretHistory12(id1: any) {
  const db = getFirestore(otherApp);
  let querySnapshot;
  try {
    const q = query(
      collection(db, "usersecret", id1, "history"),
      orderBy("date", "desc"),
      limit(12)
    );
    querySnapshot = await getDocs(q);
  } catch {
    querySnapshot = false;
  }
  return querySnapshot;
}
