import MenuRecordShow from "./MenuRecordShow";

import { Datas, RecordDatas } from "../../../common/types";

type Props = {
  datas: Datas;
  recordDatas: RecordDatas;
};

const RecordView: React.VFC<Props> = ({ datas, recordDatas }: Props) => {
  return (
    <>
      {recordDatas.opendMenu === "show" ? (
        <MenuRecordShow datas={datas} recordDatas={recordDatas} />
      ) : (
        <div>ここは表示されないはず</div>
      )}
    </>
  );
};
export default RecordView;
