import { firebaseApp } from "./firebase";
import {
  doc,
  collection,
  getFirestore,
  runTransaction,
  query,
  getDocs,
  getDoc,
  orderBy,
  limit,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

export async function addEvent(data: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q1 = doc(db, "setting", "Event");

  try {
    const docRef2 = await runTransaction(db, async (transaction) => {
      // ドキュメントの現在の値を取得
      const updatedDocRef = await transaction.get(q1);
      const currentEventCount = updatedDocRef.data()?.EventCount || 0;

      // カウントをインクリメント
      const updateEventCount = currentEventCount + 1;

      // EventBBSCountを更新
      transaction.update(q1, { EventCount: updateEventCount });

      // BBSコレクションに新しいドキュメントを追加
      let q = collection(db, "Event");
      const docRef2 = await addDoc(q, { ...data, num: updateEventCount });

      return docRef2;
    });

    return docRef2;
  } catch (e) {
    console.error("Transaction failed: ", e);
    throw e;
  }
}

export async function getEvents(): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "Event"), orderBy("priority"), limit(50));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function updateEvent(eventId: any, data: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "Event", eventId);
  const docRef = await updateDoc(q, data);
  return docRef;
}

export async function deleteEvent(eventId: any): Promise<void> {
  const db = getFirestore(firebaseApp);
  const eventRef = doc(db, "Event", eventId);
  await deleteDoc(eventRef);
}

export async function getEvent(eventId: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "Event", eventId);
  let docRef = await getDoc(q);
  return docRef;
}

export async function updateEventParticipants(
  eventId: any,
  userId: any,
  data: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "Event", eventId, "Participants", userId);
  const docRef = await updateDoc(q, data);
  return docRef;
}

export async function setEventParticipants(
  eventId: any,
  userId: any,
  data: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "Event", eventId, "Participants", userId);
  const docRef = await setDoc(q, data);
  return docRef;
}

export async function deleteEventParticipants(
  eventId: any,
  userId: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let docRef = doc(db, "Event", eventId, "Participants", userId);
  await deleteDoc(docRef);
  return true;
}

export async function getEventParticipants(eventId: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "Event", eventId, "Participants"), limit(50));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function addEventBBS(eventId: any, data: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q1 = doc(db, "Event", eventId);

  try {
    const docRef2 = await runTransaction(db, async (transaction) => {
      // ドキュメントの現在の値を取得
      const updatedDocRef = await transaction.get(q1);
      const currentEventBBSCount = updatedDocRef.data()?.EventBBSCount || 0;

      // カウントをインクリメント
      const updateEventBBSCount = currentEventBBSCount + 1;

      // EventBBSCountを更新
      transaction.update(q1, { EventBBSCount: updateEventBBSCount });

      // BBSコレクションに新しいドキュメントを追加
      let q = collection(db, "Event", eventId, "BBS");
      const docRef2 = await addDoc(q, { ...data, num: updateEventBBSCount });

      return docRef2;
    });

    return docRef2;
  } catch (e) {
    console.error("Transaction failed: ", e);
    throw e;
  }
}

export async function getEventBBSs(eventId: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "Event", eventId, "BBS"),
    orderBy("num"),
    limit(50)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function addEventRoute(eventId: any, data: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q1 = doc(db, "Event", eventId);

  try {
    const docRef2 = await runTransaction(db, async (transaction) => {
      // ドキュメントの現在の値を取得
      const updatedDocRef = await transaction.get(q1);
      const currentEventRouteCount = updatedDocRef.data()?.EventRouteCount || 0;

      // カウントをインクリメント
      const updateEventRouteCount = currentEventRouteCount + 1;

      // EventRouteCountを更新
      transaction.update(q1, { EventRouteCount: updateEventRouteCount });

      // Routeコレクションに新しいドキュメントを追加
      let q = collection(db, "Event", eventId, "Route");
      const docRef2 = await addDoc(q, { ...data, num: updateEventRouteCount });

      return docRef2;
    });

    return docRef2;
  } catch (e) {
    console.error("Transaction failed: ", e);
    throw e;
  }
}

export async function getEventRoutes(eventId: any): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "Event", eventId, "Route"),
    orderBy("num"),
    limit(50)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function deleteEventRoutes(
  eventId: any,
  RouteId: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let docRef = doc(db, "Event", eventId, "Route", RouteId);
  await deleteDoc(docRef);
  return true;
}
export async function updateEventRoutes(
  eventId: any,
  RouteId: any,
  data: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "Event", eventId, "Route", RouteId);
  const docRef = await updateDoc(q, data);
  return docRef;
}

export async function getEventRouteImages(
  eventId: any,
  routeId: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "Event", eventId, "Route", routeId, "EventRouteImage"),
    orderBy("num"),
    limit(50)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function updateEventRouteImage(
  eventId: any,
  RouteId: any,
  EventRouteImageId: any,
  data: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(
    db,
    "Event",
    eventId,
    "Route",
    RouteId,
    "EventRouteImage",
    EventRouteImageId
  );
  const docRef = await updateDoc(q, data);
  return docRef;
}

// export async function addEventRouteImage(
//   eventId: string,
//   routeId: string,
//   data: any
// ): Promise<string> {
//   const db = getFirestore(firebaseApp);
//   const colRef = collection(
//     db,
//     "Event",
//     eventId,
//     "Route",
//     routeId,
//     "EventRouteImage"
//   );

//   // 新規ドキュメントを追加し、IDを自動生成
//   const docRef = await addDoc(colRef, data);

//   // 自動生成されたIDを返す
//   return docRef.id;
// }

export async function addEventRouteImage(
  eventId: string,
  routeId: string,
  data: any
): Promise<string> {
  const db = getFirestore(firebaseApp);

  // ドキュメント参照
  const routeDocRef = doc(db, "Event", eventId, "Route", routeId);

  try {
    // トランザクション開始
    const docRef = await runTransaction(db, async (transaction) => {
      // 現在のカウント数を取得
      const routeDoc = await transaction.get(routeDocRef);
      const currentImageCount = routeDoc.data()?.EventRouteImageCount || 0;

      // カウントをインクリメント
      const updatedImageCount = currentImageCount + 1;

      // カウントを更新
      transaction.update(routeDocRef, {
        EventRouteImageCount: updatedImageCount,
      });

      // 新しい画像データを追加
      const colRef = collection(
        db,
        "Event",
        eventId,
        "Route",
        routeId,
        "EventRouteImage"
      );

      const newDocRef = await addDoc(colRef, {
        ...data,
        num: updatedImageCount, // インクリメントしたカウント番号を付与
      });

      return newDocRef.id; // 新しく追加したドキュメントのIDを返す
    });

    return docRef; // 成功した場合はIDを返す
  } catch (e) {
    console.error("Transaction failed: ", e);
    throw e; // エラー発生時は呼び出し元にエラーをスロー
  }
}

export async function getGroupPhotoImages(
  eventId: any,
  routeId: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "Event", eventId, "GroupPhoto"),
    orderBy("num"),
    limit(50)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function updateGroupPhotoImage(
  eventId: any,
  groupPhotoId: any,
  data: any
): Promise<any> {
  const db = getFirestore(firebaseApp);
  let q = doc(db, "Event", eventId, "GroupPhoto", groupPhotoId);
  const docRef = await updateDoc(q, data);
  return docRef;
}

export async function addGroupPhotoImage(
  eventId: string,
  data: any
): Promise<string> {
  const db = getFirestore(firebaseApp);

  // ドキュメント参照
  const routeDocRef = doc(db, "Event", eventId);

  try {
    // トランザクション開始
    const docRef = await runTransaction(db, async (transaction) => {
      // 現在のカウント数を取得
      const routeDoc = await transaction.get(routeDocRef);
      const currentImageCount = routeDoc.data()?.GroupPhotoCount || 0;

      // カウントをインクリメント
      const updatedImageCount = currentImageCount + 1;

      // カウントを更新
      transaction.update(routeDocRef, {
        GroupPhotoCount: updatedImageCount,
      });

      // 新しい画像データを追加
      const colRef = collection(db, "Event", eventId, "groupPhoto");

      const newDocRef = await addDoc(colRef, {
        ...data,
        num: updatedImageCount, // インクリメントしたカウント番号を付与
      });

      return newDocRef.id; // 新しく追加したドキュメントのIDを返す
    });

    return docRef; // 成功した場合はIDを返す
  } catch (e) {
    console.error("Transaction failed: ", e);
    throw e; // エラー発生時は呼び出し元にエラーをスロー
  }
}
