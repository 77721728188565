import { Dataset } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";

// 1. イベントデータの型定義
// 各イベントには id, title, date, location, image などが含まれる
type EventBlockShowProps = {
  events: Array<{
    id: string;
    title: string;
    date: string;
    location: string;
    image: string;
    soldOut: boolean;
    participants: string[];
    communityInfo: string;
    clickAction: string;
  }>;
};

const EventBlockShow: React.FC<EventBlockShowProps> = ({ events }) => {
  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {/* 2. 各イベントをループして表示 */}
      {events.map((event, index) => (
        <div
          key={index}
          style={{
            flex: "0 0 95%", // 各イベントの幅を調整
            maxWidth: "95%",
            padding: "0.25rem", // マージンを追加
          }}
        >
          {/* 3. イベント全体の外枠コンテナ */}
          <div
            style={{
              height: "100%",
              position: "relative",
              aspectRatio: "1 / 1", // 正方形にする
              overflow: "hidden", // 要素が枠外にはみ出さないようにする
              borderRadius: "1rem", // 四隅を丸める
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // 少し立体感を追加
            }}
          >
            <a
              href="#"
              style={{
                textDecoration: "none",
                display: "block",
                height: "100%",
              }}
              onClick={(e) => {
                // e.preventDefault();
                if (event.clickAction === "Record") {
                  navigate(`/Record/${event.id}`); // 詳細ページに遷移
                } else if (event.clickAction === "Event") {
                  navigate(`/Event/${event.id}`); // 詳細ページに遷移
                } else if (event.clickAction === "EditEvent") {
                  navigate(`/EditEvent/${event.id}`); // 詳細ページに遷移
                }
              }}
            >
              {/* 4. 売り切れの場合のオーバーレイ表示 */}
              {event.soldOut && (
                <img
                  src={process.env.PUBLIC_URL + "/soldOut.png"}
                  style={{
                    width: "40%",
                    height: "auto",
                    zIndex: 100,
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              )}

              {/* 5. イベントのメイン画像 */}
              <img
                data-src={event.image}
                alt={event.title}
                style={{
                  width: "100%",
                  height: "100%", // 高さを画像に合わせる
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                onError={(e: any) => {
                  e.target.src = "/images/ja2/img_noimage.gif";
                }}
                src={event.image}
              />

              {/* 6. タイトルと 7. 参加者情報を画像上に重ねる */}
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  background: "rgba(255, 255, 255, 0.9)",
                  padding: "0.05rem 0.25rem", // 上下と左右の余白を調整
                  boxSizing: "border-box",
                }}
              >
                {/* タイトル表示部分 */}
                <p
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    lineHeight: 1.4,
                    color: "#4E6D31",
                  }}
                >
                  {event.title}
                </p>

                {/* 参加者リストとコミュニティ情報 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "0rem", // 隙間を最小化
                  }}
                >
                  <ul
                    style={{
                      display: "flex",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                      marginRight: "0.5rem",
                    }}
                  >
                    {event.participants.map((participant, i) => (
                      <li
                        key={i}
                        style={{
                          marginLeft: i === 0 ? "0" : "-10px", // 横に少し重ねる
                          zIndex: event.participants.length - i, // 重なり順を調整
                        }}
                      >
                        <img
                          src={participant}
                          style={{
                            width: "clamp(16px, 5vw, 32px)", // スマホ時にさらにサイズを縮小
                            height: "clamp(16px, 5vw, 32px)",
                            borderRadius: "50%",
                            border: "2px solid white", // 枠線を追加して見やすく
                          }}
                          alt=""
                          onError={(e: any) => {
                            e.target.src = "/images/ja2/img_noimage.gif";
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                  <p
                    style={{
                      margin: 0, // 余白を削除
                      fontSize: "clamp(2px, 2.5vw, 0.75rem)", // スマホ時にフォントサイズを縮小
                      color: "#6c757d",
                    }}
                  >
                    {event.communityInfo}
                  </p>
                </div>
              </div>

              {/* 日付と場所の表示 */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  padding: "0.5rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.25rem",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    background: "#fff",
                    paddingLeft: "1rem", // 左右に余裕を持たせる
                    paddingRight: "1rem",
                    padding: "0.25rem 0.5rem",
                    borderRadius: "9999px",
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    color: "#000",
                    display: "inline-block", // コンテンツ幅に合わせる
                  }}
                >
                  {event.date}
                </p>
                <p
                  style={{
                    margin: 0,
                    background: "rgba(0, 0, 0, 0.5)",
                    padding: "0.25rem 0.5rem",
                    borderRadius: "9999px",
                    fontSize: "0.75rem",
                    fontWeight: 500,
                    color: "#fff",
                    textAlign: "center", // 中央揃え
                    display: "inline-block", // コンテンツ幅に合わせる
                    maxWidth: "fit-content",
                  }}
                >
                  {event.location}
                </p>
              </div>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventBlockShow;
