import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { getUserSecret } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserSecret";
import { getUserPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import { firebaseApp, otherApp } from "../../../common/firebaseHelper/firebase";

import { useNavigate } from "react-router-dom";
import { Container, Grid, Button, Paper } from "@mui/material";

import createCheckoutSessionSubscription from "../../../common/stripeHelper/createCheckoutSessionSubscription";
import LodingModal from "../../Modal/LodingModal/LodingModal";
import CheckIcon from "@mui/icons-material/Check";
import RoleButtonMake from "../RoleButtonMake";
import { TramRounded } from "@mui/icons-material";

import Navbar from "../../Navbar/Navbar";

import { Datas, PaymentDatas } from "../../../common/types";
type Props = {
  datas: Datas;
  paymentDatas: PaymentDatas;
};

const Subscription: React.VFC<Props> = ({ datas, paymentDatas }: Props) => {
  //   const classes = useStyles();
  const navigate = useNavigate();
  // const [userId, setUserId] = useState("");
  // const [userIsNotLogin, setUserIsNotLogin] = useState(false);
  // const [userIsOnlyLogin, setUserIsOnlyLogin] = useState(false);
  // const [userIsStandard, setUserIsStandard] = useState(false);
  // const [userIsPro, setUserIsPro] = useState(false);
  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  const cellStyle: React.CSSProperties = {
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center",
    fontSize: "14px",
  };

  return (
    <div>
      <div style={{ height: "6vh" }}></div>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h2 id="transition-modal-title">料金プラン</h2>
            </Grid>

            {!datas.userInfo.isLogin ? (
              <div style={{ width: "100%" }}>
                <br />
                アカウントをすでにお持ちですか？
                <br />
                <Button
                  disabled={false}
                  size="large"
                  variant="contained"
                  style={{ fontSize: 15 }}
                  onClick={() => {
                    navigate("/Book/signin");
                  }}
                >
                  ログイン
                </Button>
              </div>
            ) : (
              <></>
            )}

            <Grid item xs={12}>
              <br />
              <table style={{ margin: "0 auto" }}>
                <tbody>
                  <tr style={{ borderTop: "solid 3px #CCC" }}>
                    <td style={cellStyle}>プラン</td>
                    <td style={cellStyle}>Free</td>
                    <td style={cellStyle}>Standard</td>
                    <td style={cellStyle}>Pro</td>
                  </tr>
                  <tr>
                    <td style={cellStyle}>月額</td>
                    <td style={cellStyle}>無料</td>
                    <td style={cellStyle}>￥100</td>
                    <td style={cellStyle}>￥5000</td>
                  </tr>
                  <tr style={{ borderTop: "solid 3px #CCC" }}>
                    <td style={cellStyle}>point</td>
                    <td style={cellStyle}>0pt</td>
                    <td style={cellStyle}>100pt</td>
                    <td style={cellStyle}>5000pt</td>
                  </tr>

                  <tr style={{ borderBottom: "solid 3px #EEE" }}>
                    <td style={cellStyle}></td>
                    <td style={cellStyle}>
                      {/* 左側 */}
                      <RoleButtonMake
                        roleDatas={{
                          notlogin: {
                            flag: !datas.userInfo.isLogin,
                            text: "登録",
                            color: "error",
                            disabled: false,
                            excute: () => {
                              navigate("/Book/signup");
                            },
                          },
                          onlylogin: {
                            flag:
                              !datas.UserPaymentDatas.userIsStandard &&
                              !datas.UserPaymentDatas.userIsPro,
                            text: "確認",
                            color: "primary",
                            disabled: false,
                            excute: () => {
                              navigate("/Payment/history");
                            },
                          },
                          standard: {
                            flag: datas.UserPaymentDatas.userIsStandard,
                            text: "不要",
                            color: "primary",
                            disabled: TramRounded,
                            excute: () => {},
                          },
                          pro: {
                            flag: datas.UserPaymentDatas.userIsPro,
                            text: "不要",
                            color: "primary",
                            disabled: true,
                            excute: () => {},
                          },
                        }}
                      />
                    </td>

                    <td style={cellStyle}>
                      {/* 真ん中 */}
                      <RoleButtonMake
                        roleDatas={{
                          notlogin: {
                            flag: !datas.userInfo.isLogin,
                            text: "登録",
                            color: "error",
                            disabled: false,
                            excute: () => {
                              navigate("/Book/signup");
                            },
                          },
                          onlylogin: {
                            flag:
                              !datas.UserPaymentDatas.userIsStandard &&
                              !datas.UserPaymentDatas.userIsPro,
                            text: "登録",
                            color: "error",
                            disabled: false,
                            excute: () => {
                              setLodingmodalopen(true);
                              createCheckoutSessionSubscription(
                                datas.userInfo.userUid,
                                "standard"
                              );
                            },
                          },
                          standard: {
                            flag: datas.UserPaymentDatas.userIsStandard,
                            text: "確認",
                            color: "primary",
                            disabled: false,
                            excute: () => {
                              navigate("/Payment/history");
                            },
                          },
                          pro: {
                            flag: datas.UserPaymentDatas.userIsPro,
                            text: "不可",
                            color: "primary",
                            disabled: true,
                            excute: () => {},
                          },
                        }}
                      />
                    </td>

                    <td style={cellStyle}>
                      {/* 右：プロ枠 */}
                      <RoleButtonMake
                        roleDatas={{
                          notlogin: {
                            flag: !datas.userInfo.isLogin,
                            text: "登録",
                            color: "error",
                            disabled: false,
                            excute: () => {
                              navigate("/Book/signup");
                            },
                          },
                          onlylogin: {
                            flag:
                              !datas.UserPaymentDatas.userIsStandard &&
                              !datas.UserPaymentDatas.userIsPro,
                            text: "登録",
                            color: "error",
                            disabled: false,
                            excute: () => {
                              setLodingmodalopen(true);
                              createCheckoutSessionSubscription(
                                datas.userInfo.userUid,
                                "pro"
                              );
                            },
                          },
                          standard: {
                            flag: datas.UserPaymentDatas.userIsStandard,
                            text: "不可",
                            color: "primary",
                            disabled: true,
                            excute: () => {},
                          },
                          pro: {
                            flag: datas.UserPaymentDatas.userIsPro,
                            text: "確認",
                            color: "primary",
                            disabled: false,
                            excute: () => {
                              navigate("/Payment/history");
                            },
                          },
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <div style={{ height: "2vh" }}></div>
    </div>
  );
};
export default Subscription;
