export const lighttheme = {
  type: "light",
  line01: "1px solid #dddddd", // 明るい灰色
  line02: "1px solid #000000", // 黒色
  line03: "#000000", // 黒色

  back01: "#ffffff", // 白色
  back02: "#919191", // 淡い灰色
  back03: "#464646", // 濃い灰色
  back04: "#7BAB4F", // 山手色
  back05: "#D9E7C9", // 薄い山手色
  back06: "#4E6D31", // 濃い山手色

  opacity01: "rgba(255, 255, 255, 0.7)",

  text01: { fontColor: "#555555", fontSize: "130px", fontWeight: "bold" }, // 暗い灰色
  text02: { fontColor: "#000000", fontSize: "15px", fontWeight: "bold" }, // 黒色
  text03: { fontColor: "#192B35", fontSize: "1px", fontWeight: "bold" }, // 紺色
  text04: { fontColor: "#D3A15B", fontSize: "1px", fontWeight: "bold" }, //
  text05: { fontColor: "#FFFFFF", fontSize: "1px", fontWeight: "bold" }, //白
};
