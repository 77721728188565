import React, { useReducer, useEffect, useState, useMemo } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import "./App.css";

import Router from "./Router";

import { getDatas } from "./common/firebaseHelper/FirebaseTransactionHelper";
import { lighttheme } from "./common/colors";

import { otherApp } from "./common/firebaseHelper/firebase";
import { getUserPublic } from "./common/firebaseHelper/FirebaseTransactionHelperUserPublic";
import {
  getUserSecret,
  getUserSecretPointCanUse,
  getUserSecretHistory12,
} from "./common/firebaseHelper/FirebaseTransactionHelperUserSecret";

import {
  getCustomersSubscriptions,
  getCustomersPayment,
} from "./common/firebaseHelper/FirebaseTransactionHelperCustomer";

import moment from "moment";
import { useWindowSize } from "react-use";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import { adminUid } from "./common/AdminConfig";

import { UserInfo } from "./common/types";

function App() {
  const [isParamReady, setIsParamReady] = useState(false);
  const { width, height } = useWindowSize();
  const [bgColor, setBgColor] = useState({});
  const [styletext, setStyles] = useState({
    widthUnder: 100,
    windowWidth: 100,
    displayWidth: 100,
    windowHeight: 100,
  });

  const [routeList, setRouteList] = useState([]);

  const [userInfo, setUserInfo] = useState<UserInfo>({
    isLoding: true,
    user: null,
    isLogin: false,
    userUid: "",
    userEmail: "",
    avatar: {
      img: "../nanashi.png",
      name: "名無し",
    },
    adminFlag: false,
  });

  ///////////////////

  const [isCheck, setIsCheck] = useState({
    check1: false,
    check2: false,
    check3: false,
  });
  const [userPoint, setUserPoint] = useState("");
  const [userFreePoint, setUserFreePoint] = useState("");

  const [userIsStandard, setUserIsStandard] = useState(false);
  const [userIsPro, setUserIsPro] = useState(false);

  const [userPointHistory, setUserPointHistory] = useState<any[]>([]);

  const [subscriptionsdatas, setSubscriptionsdatas] = useState<any[]>([]);

  const [paymentdatas, setPaymentdatas] = useState<any[]>([]);

  const parseDate = (dateTimeStr: any) => {
    const date = new Date(dateTimeStr * 1000);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    return (
      year +
      "/" +
      month +
      "/" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  const parseAsMoment = (dateTimeStr: any) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  const initSetStyle = () => {
    if (width < 300) {
      setStyles({
        widthUnder: 300,
        windowWidth: width,
        displayWidth: width,
        windowHeight: height,
      });
    } else if (width < 500) {
      setStyles({
        widthUnder: 500,
        windowWidth: width,
        displayWidth: width,
        windowHeight: height,
      });
    } else if (width < 700) {
      setStyles({
        widthUnder: 700,
        windowWidth: width,
        displayWidth: width,
        windowHeight: height,
      });
    } else if (width < 900) {
      setStyles({
        widthUnder: 900,
        windowWidth: width,
        displayWidth: width,
        windowHeight: height,
      });
    } else if (width < 1200) {
      setStyles({
        widthUnder: 1200,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1300) {
      setStyles({
        widthUnder: 1300,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1400) {
      setStyles({
        widthUnder: 1400,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1600) {
      setStyles({
        widthUnder: 1600,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1700) {
      setStyles({
        widthUnder: 1700,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1800) {
      setStyles({
        widthUnder: 1800,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else if (width < 1900) {
      setStyles({
        widthUnder: 1900,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    } else {
      setStyles({
        widthUnder: 2000,
        windowWidth: width,
        displayWidth: width * 0.6,
        windowHeight: height,
      });
    }
  };

  const initRoutelist = async () => {
    const querySnapshot = await getDatas();
    let lists: any = [];
    querySnapshot.forEach((doc: any) => {
      lists.push({
        id: doc.id,
        num: doc.data().num,
        text: doc.data().text,
        describe: doc.data().describe,
        isActive: true,
      });
    });
    setRouteList(lists);
  };

  const initUser = async () => {
    return new Promise<void>((resolve) => {
      //リスナーを登録するが２回以上動かさないようにしている
      const unsubscribe = onAuthStateChanged(
        getAuth(otherApp),
        async (user) => {
          if (user) {
            await initUserInfo(user);
            await initUserSubscription(user);
          } else {
            //ログインしていない場合
            await initUserIsNotLogin();
          }
          unsubscribe(); // 1回のみ実行
          resolve();
        }
      );
    });
  };

  const initUserIsNotLogin = async () => {
    setUserInfo({
      isLoding: false,
      user: null,
      isLogin: false,
      userUid: "",
      userEmail: "",
      avatar: {
        img: "../nanashi.png",
        name: "名無し",
      },
      adminFlag: false,
    });
    setUserIsStandard(false);
    setUserIsPro(false);
    setUserPoint("0");
    setUserFreePoint("0");
    setSubscriptionsdatas([]);
    setUserPointHistory([]);
    setPaymentdatas([]);
  };

  const additionalLoadWithSet = async (checks: {
    check1: boolean;
    check2: boolean;
    check3: boolean;
  }) => {
    await setIsCheck(checks);
    //useEffectで待ち受けて，additionalLoadが呼ばれる仕組み
  };

  const additionalLoad = async () => {
    if (
      UserPaymentDatas.isCheck.check1 &&
      !userInfo.isLoding &&
      userInfo.user
    ) {
      console.log("アディショナルムーブ");
      checkPaySub(userInfo.user);
    }
  };

  const checkPaySub = async (user: any) => {
    // await initUserSubscription(user);
    await initUserPoint(user);
    await initUserPointHistory(user);
    await initUserPaymentdatas(user);
    await initUserSubscriptionHistory(user);
  };

  const initUserInfo = async (user: any) => {
    //ログインしている場合
    const thisAdminFlag = adminUid.includes(user.uid) ? true : false;
    const avatarDoc = await getUserPublic(user.uid);
    if (typeof avatarDoc.data() !== "undefined" && avatarDoc.data().imageurl) {
      //画像登録されている場合
      setUserInfo({
        isLoding: false,
        user: user,
        isLogin: true,
        userUid: user.uid,
        userEmail: user.email ?? "",
        avatar: {
          img: avatarDoc.data().imageurl,
          name: avatarDoc.data().name,
        },
        adminFlag: thisAdminFlag,
      });
    } else {
      //画像登録されていない場合
      setUserInfo({
        isLoding: false,
        user: user,
        isLogin: true,
        userUid: user.uid,
        userEmail: user.email ?? "",
        avatar: {
          img: "../nanashi.png",
          name: "名無し",
        },
        adminFlag: thisAdminFlag,
      });
    }
  };

  const initUserSubscription = async (user: any) => {
    //サブスク登録状況確認
    await user.getIdToken(true);
    const decodedToken = await user.getIdTokenResult();
    // console.log(decodedToken.claims.stripeRole);
    if (decodedToken.claims.stripeRole == "standard") {
      setUserIsStandard(true);
    } else if (decodedToken.claims.stripeRole == "pro") {
      setUserIsPro(true);
    }
  };

  const initUserPoint = async (user: any) => {
    //point確認
    const doc = await getUserSecret(user.uid);
    const data = doc.data();
    setUserPoint((data?.point ?? 0).toString());
    setUserFreePoint((data?.freepoint ?? 0).toString());
  };

  const initUserPointHistory = async (user: any) => {
    //point履歴確認
    const pointhistoryquerySnapshot = await getUserSecretHistory12(user.uid);

    let makepointhistorydatas: any[] = [];
    if (
      pointhistoryquerySnapshot &&
      typeof pointhistoryquerySnapshot !== "boolean"
    ) {
      pointhistoryquerySnapshot.forEach((doc) => {
        if (doc.data()) {
          let expirationDate;
          if (doc.data().expirationDate) {
            expirationDate = parseAsMoment(doc.data().expirationDate.toDate())
              .format("YYYY/MM/DD HH:mm")
              .toString();
          } else {
            expirationDate = "-";
          }

          //console.log(doc.data());

          makepointhistorydatas.push({
            date: parseAsMoment(doc.data().date.toDate())
              .format("YYYY/MM/DD HH:mm")
              .toString(),
            expirationDate: expirationDate,
            pointchange: doc.data().pointchange,
            text1: doc.data().text1,
            text2: doc.data().text2,
            lonpaID: doc.data().lonpaID,
            uid: doc.data().uid,
          });
        }
      });
    }
    setUserPointHistory(makepointhistorydatas);
    //console.log(makepointhistorydatas);
  };

  const initUserPaymentdatas = async (user: any) => {
    //CustomersPayment確認
    const paymentquerySnapshot = await getCustomersPayment(user.uid);
    let makepaymentdatas: any[] = [];

    if (paymentquerySnapshot && typeof paymentquerySnapshot !== "boolean") {
      paymentquerySnapshot.forEach((doc) => {
        if (doc.data()) {
          const createDate = parseDate(doc.data().created);
          const amount = doc.data().amount;
          makepaymentdatas.push({
            created: createDate,
            amount: amount,
          });
        }
      });
    }
    setPaymentdatas(makepaymentdatas);
  };

  const initUserSubscriptionHistory = async (user: any) => {
    //サブスク履歴確認
    const subscriptionsquerySnapshot = await getCustomersSubscriptions(
      user.uid
    );
    let makesubscriptionsdatas: any[] = [];

    if (
      subscriptionsquerySnapshot &&
      typeof subscriptionsquerySnapshot !== "boolean"
    ) {
      subscriptionsquerySnapshot.forEach((doc) => {
        if (doc.data()) {
          // console.log(doc.data());
          let canceled;
          if (doc.data().canceled_at) {
            canceled = parseDate(doc.data().canceled_at.seconds);
          } else {
            canceled = "継続中";
          }
          makesubscriptionsdatas.push({
            created: parseDate(doc.data().created.seconds),
            canceld: canceled,
            amount: doc.data().items[0].plan.amount,
            role: doc.data().role,
          });
        }
      });
    }
    setSubscriptionsdatas(makesubscriptionsdatas);
  };

  const UserPaymentDatas = useMemo(() => {
    return {
      get userIsStandard() {
        return userIsStandard;
      },
      get userIsPro() {
        return userIsPro;
      },
      get userPoint() {
        return userPoint;
      },
      get userFreePoint() {
        return userFreePoint;
      },
      get subscriptionsdatas() {
        return subscriptionsdatas;
      },
      get userPointHistory() {
        return userPointHistory;
      },
      get paymentdatas() {
        return paymentdatas;
      },
      get isCheck() {
        return isCheck;
      },
      additionalLoadWithSet,
    };
  }, [
    userIsStandard,
    userIsPro,
    userPoint,
    userFreePoint,
    subscriptionsdatas,
    userPointHistory,
    paymentdatas,
    isCheck,
  ]);

  const datas = useMemo(
    () => ({
      get bgColor() {
        return bgColor;
      },
      setBgColor,
      get styletext() {
        return styletext;
      },
      setStyles,
      get routeList() {
        return routeList;
      },
      setRouteList,
      get userInfo() {
        return userInfo;
      },
      setUserInfo,
      get UserPaymentDatas() {
        return UserPaymentDatas;
      },
      initUser,
      initUserIsNotLogin,
    }),
    [bgColor, styletext, routeList, userInfo, UserPaymentDatas]
  );

  useEffect(() => {
    console.log("uE_App");
    const f = async () => {
      await setBgColor(lighttheme);
      await initSetStyle();
      await initRoutelist();
      await initUser();
    };
    f();
  }, []);

  // 状態が変更されたときに後続処理を実行
  useEffect(() => {
    console.log("uE_App_isLoding");
    if (!userInfo.isLoding) {
      additionalLoad();
      setIsParamReady(true);
    }
  }, [userInfo.isLoding, UserPaymentDatas.isCheck]); // isLoding の変更を監視

  return <>{isParamReady ? <Router datas={datas} /> : <></>}</>;
}

export default App;
