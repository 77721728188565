import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, BookDatas } from "../../../common/types";

import PastEvents from "./PastEvents";
import EventBlockShow from "./EventBlockShow";

type Props = {
  datas: Datas;
  bookDatas: BookDatas;
};

const MenuEvent: React.VFC<Props> = ({ datas, bookDatas }: Props) => {
  const navigate = useNavigate();
  const cellStyle: React.CSSProperties = {
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "14px",
    textAlign: "center", // 水平方向の中央揃え
    // width: "fit-content", // コンテンツ幅に合わせる
    marginLeft: "auto", // 左右中央に配置
    marginRight: "auto",
  };
  return (
    <div>
      <div
        style={{ textAlign: "center", backgroundColor: datas.bgColor.back05 }}
      >
        🔳 開催予定イベント
      </div>
      <br />

      <Grid container spacing={3} padding={2}>
        {bookDatas.eventFutureList.map((item: any, index: any) => (
          <Grid item xs={6} sm={4} md={3} lg={3} key={item.id}>
            <EventBlockShow
              events={[
                {
                  id: item.id,
                  title: item.title,
                  date: item.eventDateString,
                  location: item.eki + "駅",
                  image: item.gatheringPlace.imageURL,
                  soldOut: false,
                  participants: item.ParticipantsImg,
                  communityInfo: "公式イベント",
                  clickAction: "Event",
                },
              ]}
            />
          </Grid>
        ))}
      </Grid>
      {/* <table
        style={{
          width: datas.styletext.displayWidth,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center", // テーブル内のコンテンツを中央揃え
        }}
      >
        <tbody>
          {bookDatas.eventFutureList.map((item: any, index: any) => (
            <tr>
              <td style={cellStyle}>{item.title}</td>



              <td style={cellStyle}>
                <Button
                  style={{
                    display: "inline-block",
                    padding: "10px 10px",
                    fontSize: "18px",
                    color: "#fff",
                    backgroundColor: datas.bgColor.back06,
                    textDecoration: "none",
                    borderRadius: "5px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease",
                  }}
                  onClick={() => {
                    navigate("/Event/" + item.id);
                  }}
                >
                  詳細
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}
      <br />
      <div
        style={{ textAlign: "center", backgroundColor: datas.bgColor.back05 }}
      >
        🔳 過去イベントのレポート
      </div>
      <br />
      <br />
      <PastEvents eventPastList={bookDatas.eventPastList} />
    </div>
  );
};

export default MenuEvent;
