import { useState } from "react";

// import { useStyles } from "../Styles";
import { useNavigate } from "react-router-dom";

import LodingModal from "../../Modal/LodingModal/LodingModal";

// import { userApp } from "../common/firebase";

import { Datas } from "../../../common/types";

import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

import { Button } from "@mui/material";

type Props = { datas: Datas };

const StripeSuccess: React.VFC<Props> = ({ datas }: Props) => {
  const navigate = useNavigate();

  const [lodingmodalopen, setLodingmodalopen] = useState(false);

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };

  return (
    <>
      <Navbar datas={datas} />

      <div
        style={
          datas.styletext.windowWidth > 1200
            ? styles1.container
            : styles2.container
        }
      >
        <div style={{ textAlign: "center" }}>
          <p>ポイントチャージ成功！</p>
          <p>下記ボタンよりポイントの確認が可能です。</p>
          <Button
            onClick={() => {
              navigate("/Payment/history");
            }}
          >
            ポイントを見る
          </Button>
        </div>
      </div>

      <Footer datas={datas} />

      {lodingmodalopen ? <LodingModal open={lodingmodalopen} /> : <></>}
    </>
  );
};
export default StripeSuccess;
