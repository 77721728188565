import { onSnapshot } from "firebase/firestore";

import { createCheckoutSessions } from "../../common/firebaseHelper/FirebaseTransactionHelperStripe";

import getStripe from "./initializeStripe";

export async function createCheckoutSessionOneBuy(uid: string, plan: string) {
  //const firestore = firebase.firestore();

  // プラン情報を定義
  const planInfo: Record<string, { price: string }> = {
    onebuy100: { price: "price_1QYlDDLAUxEuQidCpLbXIMQn" },
    onebuy1000: { price: "price_1QbXhELAUxEuQidCQ3PSv8iM" },
    onebuy10000: { price: "price_1QbXi8LAUxEuQidCeNR0QgKw" },
  };

  // プランが有効であるか確認
  const selectedPlan = planInfo[plan];
  if (!selectedPlan) {
    console.error("Invalid plan selected");
    return;
  }

  // 共通データ構造を作成
  const data = {
    price: selectedPlan.price,
    quantity: 1,
    mode: "payment",
    success_url: `${window.location.origin}/StripeSuccess`,
    cancel_url: `${window.location.origin}/StripeError`,
    metadata: {
      uid: uid, // ユーザーIDをメタデータとして設定
      service: "boukenguild",
      plan: plan, // プラン情報も含める
      id: "none",
    },
  };

  const docRef = await createCheckoutSessions(uid, data);

  const unsub = onSnapshot(docRef, async (snap: any) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      const stripe = await getStripe();
      if (stripe) {
        stripe.redirectToCheckout({ sessionId });
      }
    }
  });
}

export default createCheckoutSessionOneBuy;
