import { useEffect, useState, useMemo } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { useStyles } from "../Styles";
import { useNavigate } from "react-router-dom";

import LodingModal from "../../Modal/LodingModal/LodingModal";

// import { userApp } from "../common/firebase";

import { Datas } from "../../../common/types";

import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

import PointModal from "../../Modal/PointModal/PointModal";
import PaymentView from "./PaymentView";

import { otherApp } from "../../../common/firebaseHelper/firebase";
import { useParams } from "react-router-dom";

type Props = { datas: Datas };

const Payment: React.VFC<Props> = ({ datas }: Props) => {
  // const classes = useStyles();
  let { paytypeid } = useParams();
  const navigate = useNavigate();

  const [opendMenu, setOpendMenu] = useState("history");
  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };

  useEffect(() => {
    if (paytypeid == "singlepurchase") {
      setOpendMenu("singlepurchase");
    } else if (paytypeid == "subscription") {
      setOpendMenu("subscription");
    } else if (paytypeid == "history") {
      setOpendMenu("history");
    } else if (paytypeid == "cancell") {
      setOpendMenu("cancell");
    }
  }, [paytypeid]);

  useEffect(() => {
    const f = async () => {
      console.log("uE_Payment");
      setLodingmodalopen(true);

      await datas.UserPaymentDatas.additionalLoadWithSet({
        check1: true,
        check2: false,
        check3: false,
      });
      setLodingmodalopen(false);
    };
    f();
  }, []);

  const paymentDatas = useMemo(() => {
    return {
      get opendMenu() {
        return opendMenu;
      },
      setOpendMenu,
    };
  }, [opendMenu]);

  return (
    <>
      <Navbar datas={datas} />

      <div
        style={
          datas.styletext.windowWidth > 1200
            ? styles1.container
            : styles2.container
        }
      >
        <PaymentView datas={datas} paymentDatas={paymentDatas} />
      </div>

      <Footer datas={datas} />

      {lodingmodalopen ? <LodingModal open={lodingmodalopen} /> : <></>}
    </>
  );
};
export default Payment;
