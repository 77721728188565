import { useState, useEffect, useMemo } from "react";

import { marked } from "marked";

import { useNavigate } from "react-router-dom";
import { Datas, Participant } from "../../../common/types";
import { useParams } from "react-router-dom";

import { otherApp } from "../../../common/firebaseHelper/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import {
  getEvent,
  getEventBBSs,
  addEventBBS,
  getEventParticipants,
  getEventRouteImages,
  updateEvent,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import {
  setUserPrivateBook,
  updateUserPrivateBook,
  deleteUserPrivateBook,
  // getUserPrivatePlan,
  // setUserPrivatePlan,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";

import { getUserPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import {
  setEventParticipants,
  deleteEventParticipants,
  getEventRoutes,
  getGroupPhotoImages,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import { getListOfUsersPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import moment from "moment";

import EventView from "./EventView";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

import LodingModal from "../../Modal/LodingModal/LodingModal";
import PointModal from "../../Modal/PointModal/PointModal";
import GatyaresultModal from "../../Modal/GatyaresultModal/GatyaresultModal";

type Props = {
  datas: Datas;
};

// Markedのオプションを設定
marked.setOptions({
  breaks: true, // 改行を有効にする
  gfm: true, // GitHub Flavored Markdownを有効にする（リストなどの構文の互換性向上）
});

const Event: React.FC<Props> = ({ datas }) => {
  let { eventid, menu } = useParams();
  const [content, setContent] = useState<string>("");

  // const [htmlContent, setHtmlContent] = useState<string>("");

  const [eventData, setEventData] = useState({
    id: "",
    title: "",
    comment: "",
    eki: "",
    ekiID: "",
    eventDate: new Date(0),
    eventDateString: "",
    gatheringPlace: { id: "", imageURL: "", num: "", text: "" },
    ParticipantsImg: [],
  });

  // const [eventTitle, setEventTitle] = useState<string>("");
  // const [eventDate, setEventDate] = useState<Date>(new Date(0));
  const [eventDateString, setEventDateString] = useState("");

  const [BBS, setBBS] = useState([]);
  const [EventRoute, setEventRoute] = useState([]);
  const [GroupPhoto, setGroupPhoto] = useState([]);

  const [Gatyaresult, setGatyaresult] = useState({
    choosing: true,
    id1: "",
    id2: "",
    id3: "",
  });

  const [selectedGatyaresult, setSelectedGatyaresult] = useState({
    id: "",
    text1: "",
    text2: "",
    img: "",
  });

  const [userDatas, setUserDatas] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const [eventParticipantsList, setEventParticipantsList] = useState<
    Participant[]
  >([]);
  const [opendMenu, setOpendMenu] = useState("beforeBook");
  const [isParticipation, setIsParticipation] = useState(false);

  const [isParamReady, setIsParamReady] = useState(false);
  const [lodingmodalopen, setLodingmodalopen] = useState(true);
  const [pointmodalopen, setPointmodalopen] = useState(false);
  const [gatyaresultmodalopen, setGatyaresultmodalopen] = useState(false);

  const [isOnceMoved, setIsOneceMoved] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    console.log("test");
  };

  const incrementChangeCount = () => {
    setChangeCount(changeCount + 1);
  };

  const parseAsMoment = (dateTimeStr: any) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  const cancelUser = async (event_id: string) => {
    const auth = getAuth(otherApp);
    const user = auth.currentUser;
    if (user) {
      await deleteEventParticipants(event_id, user.uid);
      await deleteUserPrivateBook(user.uid, event_id);
      incrementChangeCount();
      setOpendMenu("beforeBook");
      // navigate("/Book");
    } else {
      // console.log("ログインしていない");
    }
  };

  //参加時に呼ばれる
  const bookUser = async (
    event_id: string,
    event_title: string,
    event_eventDate: any
  ) => {
    const auth = getAuth(otherApp);
    const user = auth.currentUser;
    if (user) {
      let doc = await getUserPublic(user.uid);
      if (typeof doc.data() !== "undefined") {
        if (typeof doc.data().name !== "undefined") {
          await setEventParticipants(event_id, user.uid, {
            uid: user.uid,
            name: doc.data().name,
          });
          await setUserPrivateBook(user.uid, event_id, {
            id: event_id,
            title: event_title,
            eventDate: event_eventDate,
          });
          incrementChangeCount();
        } else {
          console.log("name undfined");
          alert(
            "ユーザに名前が設定されていないようです。プロフィール欄で設定してください。"
          );
        }
      } else {
        console.log("data undfined");
        alert(
          "ユーザに名前が設定されていないようです。プロフィール欄で設定してください。"
        );
      }
    } else {
      // console.log("ログインしていない");
      navigate("/Book/signin");
      // setOpendMenu("signin");
    }
  };

  useEffect(() => {
    const f = async () => {
      console.log("ue_Event");
      setLodingmodalopen(true);

      //データを取得
      let doc = await getEvent(eventid);

      //イベントタイトルを設定

      //イベントの日程を設定
      if (doc && doc.data() && doc.data().title) {
        //イベントの日程を設定
        // console.log(doc.data());
        if (doc.data().eventDate) {
          const eventdatestring = parseAsMoment(doc.data().eventDate.toDate())
            .format("YYYY/MM/DD HH:mm")
            .toString();

          //コメントをマークダウンに
          const markedhtmlcomment = await marked.parse(
            doc.data().comment.replace(/\\n/g, "\n")
          );
          setEventData({
            id: doc.id,
            title: doc.data().title,
            comment: markedhtmlcomment,
            eki: doc.data().eki,
            ekiID: doc.data().ekiID,
            eventDate: doc.data().eventDate.toDate(),
            eventDateString: eventdatestring,
            gatheringPlace: doc.data().gatheringPlace,
            ParticipantsImg: doc.data().ParticipantsImg,
          });
        } else {
          setEventDateString("イベントは中止になりました:id01");
          navigate("/Book");
        }

        //掲示板を確認
        const querySnapshot = await getEventBBSs(eventid);
        let lists: any = [];
        let userlists: any = new Set();
        querySnapshot.forEach((doc: any) => {
          // console.log(doc.id);
          lists.push({
            id: doc.data().id,
            content: doc.data().content,
            uid: doc.data().uid,
          });
          userlists.add(doc.data().uid); // UIDをSetに追加
        });
        userlists = Array.from(userlists); // Setを配列に変換

        let userPublicDataMap: any = {};

        // console.log(userlists);

        if (userlists.length > 0) {
          const querySnapshot2 = await getListOfUsersPublic(userlists);
          // querySnapshot2 をマップ形式に変換
          querySnapshot2.forEach((doc: any) => {
            userPublicDataMap[doc.id] = {
              name: doc.data().name,
              imageurl: doc.data().imageurl,
            };
          });

          setUserDatas(Object.values(userPublicDataMap));

          // lists を userPublicDataMap に基づいて展開し、name と imageurl を追加
          let lists3: any = lists.map((listItem: any) => {
            const userPublicData = userPublicDataMap[listItem.uid] || {};
            return {
              ...listItem,
              name: userPublicData.name || "Unknown",
              imageurl: userPublicData.imageurl || "default_image_url",
            };
          });
          setBBS(lists3);
        } else {
          //コメント無し
        }

        //イベントルート確認
        const querySnapshot2 = await getEventRoutes(eventid);
        let lists4: any = [];

        for (const doc2 of querySnapshot2.docs) {
          // console.log(doc.id);
          let lists5: any = [];

          if (doc2.data().EventRouteImageCount > 0) {
            //イベントルート確認
            const querySnapshot3 = await getEventRouteImages(eventid, doc2.id);

            querySnapshot3.forEach((doc3: any) => {
              lists5.push({
                id: doc3.id,
                imageURL: doc3.data().imageURL,
              });
            });
          }

          lists4.push({
            id: doc2.id,
            commandId: doc2.data().id,
            num: doc2.data().num,
            text: doc2.data().text,
            EventRouteImageCount: doc2.data().EventRouteImageCount,
            Images: lists5,
          });
        }
        setEventRoute(lists4);

        if (doc.data().GroupPhotoCount > 0) {
          let lists6: any = [];
          //集合写真確認
          const querySnapshot4 = await getGroupPhotoImages(eventid, doc.id);
          querySnapshot4.forEach((doc4: any) => {
            lists6.push({
              id: doc4.id,
              imageURL: doc4.data().imageURL,
            });
          });
          setGroupPhoto(lists6);
        }
      } else {
        alert("中止になりました:id02");

        // eventidのイベントが中止になっているとき
        // userの予約しているイベント一覧から削除できるようにする

        const auth = getAuth(otherApp);
        const user = auth.currentUser;
        if (user) {
          updateUserPrivateBook(user.uid, eventid, { isDelete: true });
        }

        navigate("/Book");
      }

      // datas.initUser();

      if (menu) {
        SetOpendMenuByURL();
      }

      setLodingmodalopen(false);
      setIsParamReady(true);
    };
    f();
  }, [changeCount]);

  useEffect(() => {
    console.log("ue_Event2");
    if (datas.userInfo.isLogin) {
      const ff = async () => {
        //console.log(datas.userInfo);
        setLodingmodalopen(true);
        await checkParticipants();
        setLodingmodalopen(false);
      };
      ff();
    }
  }, [datas.userInfo, changeCount]);

  useEffect(() => {
    console.log("ue_Event3");
    if (
      eventParticipantsList &&
      eventParticipantsList.length > 0 &&
      eventData.ParticipantsImg &&
      eventData.ParticipantsImg.length > 0 &&
      !isOnceMoved
    ) {
      const fff = async () => {
        setIsOneceMoved(true);
        updateParticipantsImg();
      };
      fff();
    }
  }, [eventParticipantsList, eventData.ParticipantsImg]);

  const SetOpendMenuByURL = async () => {
    console.log(menu);
    if (menu == "eventRoute") {
      setOpendMenu("eventRoute");
    }
  };

  const PostBBS = async () => {
    if (content) {
      const auth = getAuth(otherApp);
      const unsubscribe = onAuthStateChanged(auth, async (user: any) => {
        if (user) {
          await addEventBBS(eventid, { content: content, uid: user.uid });
          incrementChangeCount();
          setContent("");
          // ここで一度だけ処理を行い、リスナーを解除
          unsubscribe();
        } else {
          console.log("ログインしてください");
          unsubscribe();
        }
      });
    } else {
      console.log("何も記入されていません");
    }
  };

  // イベント参加者の取得
  const checkParticipants = async () => {
    const querySnapshot3 = await getEventParticipants(eventid);
    let lists3: any = [];
    querySnapshot3.forEach((doc: any) => {
      lists3.push({
        uid: doc.data().uid,
        name: doc.data().name,
      });
    });

    // lists3が空かどうかを判定
    if (lists3.length > 0) {
      // UIDのリストを抽出
      const uidList = lists3.map((participant: any) => participant.uid);

      // console.log(uidList);
      // 各ユーザの画像取得
      const userSnapshot = await getListOfUsersPublic(uidList);
      const participantsWithImages: any[] = [];
      userSnapshot.forEach((doc: any) => {
        participantsWithImages.push({
          uid: doc.id, // document ID = uid
          name: doc.data().name || null,
          image: doc.data().imageurl || null, // `image` フィールドを想定
        });
      });

      setEventParticipantsList(participantsWithImages);

      if (datas.userInfo.isLogin) {
        const isUserInList = lists3.some(
          (participant: any) => participant.uid === datas.userInfo.userUid
        );
        setIsParticipation(isUserInList);

        if (opendMenu == "beforeBook") {
          setOpendMenu(isUserInList ? "afterBook" : "beforeBook");
        } else if (opendMenu == "eventRoute") {
          console.log(opendMenu);
        }
      } else {
        setIsParticipation(false);
      }
    } else {
      console.log("参加者リストが空です。");
    }
  };

  const updateParticipantsImg = async () => {
    if (
      eventParticipantsList &&
      eventParticipantsList.length > 0 &&
      eventData.ParticipantsImg &&
      eventData.ParticipantsImg.length > 0
    ) {
      // null を除外したリストを作成
      const images = eventParticipantsList
        .map((participant) => participant.image)
        .filter((image): image is string => image !== null);
      const isMatch =
        JSON.stringify(images.slice().sort()) ===
        JSON.stringify(eventData.ParticipantsImg.slice().sort());
      // console.log("順番を無視した一致:", isMatch);

      if (!isMatch) {
        console.log("不一致");
        // console.log(JSON.stringify(images.slice().sort()));
        // console.log(JSON.stringify(eventData.ParticipantsImg.slice().sort()));
        updateEvent(eventid, { ParticipantsImg: images });
      }
    } else {
      console.log("空");
    }

    //eventを更新
  };

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };

  const eventDatas = useMemo(() => {
    return {
      get opendMenu() {
        return opendMenu;
      },
      setOpendMenu,
      get PostBBS() {
        return PostBBS;
      },
      get BBS() {
        return BBS;
      },
      get eventData() {
        return {
          ...eventData,
        };
      },
      get Gatyaresult() {
        return Gatyaresult;
      },
      setGatyaresult,

      get eventParticipantsList() {
        return eventParticipantsList;
      },
      get content() {
        return content;
      },
      setContent,
      get cancelUser() {
        return cancelUser;
      },
      get bookUser() {
        return bookUser;
      },
      get isParticipation() {
        return isParticipation;
      },
      get EventRoute() {
        return EventRoute;
      },
      get GroupPhoto() {
        return GroupPhoto;
      },
      setGroupPhoto,
      setEventRoute,

      setPointmodalopen,
      incrementChangeCount,
      setGatyaresultmodalopen,
      setSelectedGatyaresult,
    };
  }, [
    opendMenu,
    BBS,
    eventData,
    eventParticipantsList,
    content,
    isParticipation,
    EventRoute,
    Gatyaresult,
  ]);

  return (
    <div>
      {isParamReady ? (
        <>
          <Navbar datas={datas} />

          <div
            style={
              datas.styletext.windowWidth > 1200
                ? styles1.container
                : styles2.container
            }
          >
            <EventView datas={datas} eventDatas={eventDatas} />
          </div>

          <Footer datas={datas} />
        </>
      ) : (
        <></>
      )}

      {pointmodalopen ? (
        <PointModal
          open={pointmodalopen}
          setOpen={setPointmodalopen}
          logtext={eventData.id}
        />
      ) : (
        <></>
      )}

      {gatyaresultmodalopen ? (
        <GatyaresultModal
          open={gatyaresultmodalopen}
          setOpen={setGatyaresultmodalopen}
          selectedGatyaresult={selectedGatyaresult}
        />
      ) : (
        <></>
      )}

      <LodingModal open={lodingmodalopen} />
    </div>
  );
};

export default Event;
