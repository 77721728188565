import React from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Paper, Typography, Box, Grid } from "@mui/material";

import { Datas, RecordDatas } from "../../../common/types";

type Props = {
  datas: Datas;
  recordDatas: RecordDatas;
};

const MenuRecordShow: React.VFC<Props> = ({ datas, recordDatas }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h5" component="h2" gutterBottom>
            {recordDatas.recordData.title}
            <br />
            {recordDatas.recordData.eventDateString}
          </Typography>
          <br />
          <br />
          {/* 画像一覧を表示 */}
          <Box>
            {recordDatas.EventRoute.map((route, index) => (
              <Box key={route.id} sx={{ marginBottom: 4 }}>
                <Typography variant="h6" gutterBottom>
                  {`${index + 1}. ${route.text}`}
                </Typography>
                <Grid container spacing={2}>
                  {route.Images.map((image) => (
                    <Grid item xs={12} sm={6} md={4} key={image.id}>
                      <img
                        src={image.imageURL}
                        alt={route.text}
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "8px",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </Box>
          <br />
        </Paper>
        <br />
      </Container>
    </>
  );
};
export default MenuRecordShow;
