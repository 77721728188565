import { useRef } from "react";
import { Box, CircularProgress, Typography, Avatar } from "@mui/material";
import React from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { firebaseApp } from "../firebaseHelper/firebase";

import { updateGatheringPlace } from "../firebaseHelper/FirebaseTransactionHelperGatheringPlace";

import Compressor from "compressorjs";
import Resizer from "react-image-file-resizer";

type Props = {
  // eki_id: string;
  // gatheringPlace_id: string;
  imageurl: string;
  // setimageurl: any;
  setimagefunc: any;
  strageurl: string;
};

function base64ToBlob(base64: string, mimeType: string): Blob {
  const byteString = atob(base64.split(",")[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  return new Blob([arrayBuffer], { type: mimeType });
}

function resizeImage(
  file: any,
  maxWidth: any,
  maxHeight: any,
  format: any,
  quality: any
) {
  return new Promise<Blob>((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      format,
      quality,
      0,
      (result) => {
        if (typeof result === "string") {
          const blob = base64ToBlob(result, format);
          resolve(blob);
        } else {
          reject(new Error("リサイズ後の画像が取得できませんでした"));
        }
      },
      "base64"
    );
  });
}

function compressImage(file: any, quality: any) {
  return new Promise<File>((resolve, reject) => {
    new Compressor(file, {
      quality: quality,
      success(result) {
        if (result instanceof Blob) {
          const compressedFile = new File([result], file.name, {
            type: file.type,
          });
          resolve(compressedFile);
        } else {
          reject(new Error("圧縮後の結果が Blob 型ではありません"));
        }
      },
      error(err) {
        reject(err);
      },
    });
  });
}

const ImageSetting: React.FC<Props> = ({
  // eki_id,
  // gatheringPlace_id,
  imageurl,
  // setimageurl,
  strageurl,
  setimagefunc,
}) => {
  const inputRef: any = useRef(null);

  const value = 29;
  const chartcolors = { 0: "#757ce8", 1: "#ff7961" };
  const fileUpload = () => {
    inputRef.current.click();
  };
  //uploadできる画像のバイト数のリミット
  const limitUpdateByte = 300000;

  async function onSubmit(event: any) {
    event.preventDefault();
    if (typeof event.target.files[0] === "undefined") {
      console.log("ファイルが選択されていません");
      return;
    }
    let imagefile = event.target.files[0];

    // console.log("元の画像サイズ:", imagefile.size);

    // 画像をリサイズ
    const resizedBlob: Blob = await resizeImage(
      imagefile,
      480,
      480,
      "JPEG",
      70
    );

    // BlobをFileに変換
    const resizedFile = new File([resizedBlob], imagefile.name, {
      type: "image/jpeg",
    });

    // 画像を圧縮
    const compressedFile = await compressImage(resizedFile, 0.6);

    const image: File = compressedFile;
    // console.log(image.size);
    // const image = avatarImage;

    if (image.size < limitUpdateByte) {
      //imgnameの名前づけ(uid+imgname)
      let imgname = Date.now();
      const storage = getStorage(firebaseApp);
      //console.log(props.lonpaid);
      const storageRef = ref(storage, strageurl + imgname); //どのフォルダの配下に入れるかを設定
      const uploadTask = uploadBytesResumable(storageRef, image); //ファイル名

      //ファイルをアップロードする
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // アップロードの進行状況を処理
        },
        (error) => {
          // アップロード中にエラーが発生した場合の処理
          console.error("アップロード中にエラーが発生しました: ", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //console.log(eki_id, shizi_id);
            const f = async () => {
              await setimagefunc(String(downloadURL));
              // await updateGatheringPlace(eki_id, gatheringPlace_id, {
              //   imageURL: String(downloadURL),
              // });
              // setimageurl(downloadURL);
              //console.log(downloadURL);
            };

            f();
          });
        }
      );
    } else {
      alert("容量が大きすぎます。やり直してください");
    }

    // },
    // error(err) {
    //   console.log(err);
    //   return;
    // },
    // });
  }
  return (
    <>
      <div
        onClick={fileUpload}
        style={{
          width: "100px",
          height: "100px",
          border: "2px dashed #ccc", // 常に枠線を表示
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          overflow: "hidden", // 画像が枠内に収まるように
        }}
      >
        {imageurl ? (
          <img
            src={imageurl}
            alt="Uploaded"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        ) : (
          <Typography color="textSecondary" variant="body2">
            クリックして画像をアップロード
          </Typography>
        )}
      </div>
      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={onSubmit}
      />
    </>
  );
};

export default ImageSetting;
