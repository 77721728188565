import React from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Paper,
  Typography,
  List,
  Divider,
  TextField,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Datas, EventDatas, Participant } from "../../../common/types";

type Props = {
  datas: Datas;
  eventDatas: EventDatas;
};

const MenuEventAfterBook: React.VFC<Props> = ({ datas, eventDatas }: Props) => {
  const navigate = useNavigate();
  const yamateLineColor = "#9acd32"; // 山手線カラー

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        {/* イベントタイトルと基本情報 */}
        <Typography variant="h4" gutterBottom>
          {eventDatas.eventData.title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          日程：{eventDatas.eventData.eventDateString}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          駅名：{eventDatas.eventData.eki}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          あなたは、参加で登録済みです
        </Typography>

        {/* イベントID */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="body2" color={datas.bgColor.back01}>
            イベントID: {eventDatas.eventData.id}
          </Typography>
        </Box>

        {/* 集合場所・散歩ルートボタン */}
        <Box
          sx={{
            my: 4,
            textAlign: "center",
            borderTop: `2px solid ${yamateLineColor}`,
            pt: 2,
          }}
        >
          <br />
          <Button
            variant="contained"
            sx={{
              py: 1.5,
              px: 3,
              fontSize: 18,
              borderRadius: 2,
              width: "100%", // 幅を100%に設定
              backgroundColor: datas.bgColor.back04,
              "&:hover": {
                backgroundColor: datas.bgColor.back06, // ホバー時の色
              },
            }}
            onClick={() => {
              eventDatas.setOpendMenu("eventRoute");
            }}
          >
            集合場所・散歩ルート
          </Button>
        </Box>

        {/* 参加者チャット */}
        <Box sx={{ my: 4, borderTop: `2px solid ${yamateLineColor}`, pt: 2 }}>
          <Typography variant="h6" gutterBottom>
            🔳 チャット
          </Typography>
          <Box
            sx={{
              maxHeight: 300,
              overflowY: "auto",
              p: 2,
              border: "1px solid #ddd",
              borderRadius: 2,
              backgroundColor: "#f9f9f9",
            }}
          >
            {eventDatas.BBS.length === 0 ? ( // チャットがない場合の表示
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ textAlign: "center", py: 2 }}
              >
                最初の投稿をしてみましょう
              </Typography>
            ) : (
              <>
                {eventDatas.BBS.map((bbsdata: any, index: any) => (
                  <Box
                    key={index}
                    sx={{
                      mb: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      borderRadius: 2,
                      p: 1,
                      backgroundColor: "#ffffff",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      sx={{ mb: 0.5 }}
                    >
                      {bbsdata.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {bbsdata.content}
                    </Typography>
                  </Box>
                ))}
              </>
            )}
          </Box>
          <TextField
            label="投稿内容"
            fullWidth
            margin="normal"
            value={eventDatas.content}
            onChange={(e) => eventDatas.setContent(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              py: 1.5,
              px: 3,
              fontSize: 18,
              borderRadius: 2,
              backgroundColor: datas.bgColor.back04,
              "&:hover": {
                backgroundColor: datas.bgColor.back06, // ホバー時の色
              },
            }}
            onClick={() => {
              eventDatas.PostBBS();
            }}
          >
            投稿する
          </Button>
        </Box>

        {/* イベント参加者 */}
        <Box sx={{ my: 4, borderTop: `2px solid ${yamateLineColor}`, pt: 2 }}>
          <Typography variant="h6" gutterBottom>
            🔳 メンバ
          </Typography>
          <Box>
            {eventDatas.eventParticipantsList.map(
              (item: Participant, index: any) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    my: 2, // 行間のマージン
                  }}
                >
                  {/* 番号部分 */}
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      backgroundColor: datas.bgColor.back04,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "18px",
                      mr: 2, // 右にマージン
                    }}
                  >
                    {index + 1}
                  </Box>

                  {/* 画像 */}
                  <Box
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      overflow: "hidden", // 画像を丸くするためにオーバーフローを隠す
                      marginRight: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `2px solid ${datas.bgColor.back04}`,
                    }}
                  >
                    <img
                      src={
                        item.image ||
                        "data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' style='background-color: lightgray;'></svg>"
                      }
                      alt={`${item.name}'s profile`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // 画像をコンテナに収める
                      }}
                    />
                  </Box>

                  {/* 名前 */}
                  <Typography
                    variant="body1"
                    sx={{
                      flex: 1, // 名前が長くても他の要素に影響を与えない
                      minWidth: "100px", // 必要に応じて最小幅を調整
                      color: "#333",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>

        {/* イベント説明・参加取り消し */}
        <Box sx={{ my: 4, borderTop: `2px solid ${yamateLineColor}`, pt: 2 }}>
          <Typography variant="h6" gutterBottom>
            🔳 イベント説明・参加取り消し
          </Typography>
          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="comment-content"
              id="comment-header"
            >
              <Typography>こちらクリック</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: eventDatas.eventData.comment,
                }}
              />
              {/* 参加状態に応じたボタン */}
              {datas.userInfo.isLogin && (
                <Box sx={{ mt: 4 }}>
                  {eventDatas.isParticipation ? (
                    <Button
                      variant="contained"
                      color="error"
                      fullWidth
                      sx={{ py: 1.5, fontSize: 16, borderRadius: 2 }}
                      onClick={() => {
                        eventDatas.cancelUser(eventDatas.eventData.id);
                      }}
                    >
                      参加を取り消す
                    </Button>
                  ) : (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      textAlign="center"
                    >
                      未参加状態です。
                    </Typography>
                  )}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>
    </Container>
  );
};

export default MenuEventAfterBook;
