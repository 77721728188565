import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import { Datas, Route, Command } from "../../../common/types";
import ImageSetting from "../../../common/lib/ImageSetting";

import { Button, TextField } from "@mui/material";

import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Timestamp } from "firebase/firestore"; // FirestoreのTimestamp型をインポート
import {
  getCommands,
  addCommand,
  updateCommand,
  deleteCommand,
  updateData,
} from "../../../common/firebaseHelper/FirebaseTransactionHelper";

import DeveloperCommandImage from "./DeveloperCommandImage";

type Props = {
  datas: Datas;

  text1: any;
  text2: any;
  eki_id: any;
  changing: any;
  setChanging: any;
  changeCount: number;
};

const DeveloperCommand: React.VFC<Props> = ({
  datas,

  text1,
  text2,
  eki_id,
  changing,
  setChanging,
  changeCount,
}: Props) => {
  const [text4, settext4] = useState("");
  const [text5, settext5] = useState("");
  const [shizi_id, setShiziId] = useState("");
  const [openingtime, setopeningtime] = useState<Date>(new Date(0));
  const [closingtime, setclosingtime] = useState<Date>(new Date(0));
  const [explainText, setExplainText] = useState("まずは駅を選んでね");
  const [changeType, setChangeType] = useState(true);

  const [commandList, setCommandList] = useState<Command[]>([]);
  const [imageurl, setimageurl] = useState("");
  const lineStyle = {
    display: "inline-block",
    width: "100%",
  };

  useEffect(() => {
    console.log("uE_DeveloperCommand");
    const f = async () => {
      if (eki_id) {
        getDataCommand(String(eki_id));
      } else {
        alert("エラーです");
      }
    };
    f();
  }, [changeCount]);

  //指令を取得する処理
  async function getDataCommand(id: string): Promise<boolean> {
    const querySnapshot = await getCommands(id);

    let lists: any = [];
    querySnapshot.forEach((doc: any) => {
      let openingtimenew;
      let closingtimenew;
      if (doc.data().openingtime instanceof Timestamp) {
        openingtimenew = doc.data().openingtime.toDate();
      } else {
        openingtimenew = new Date(0);
      }

      if (doc.data().closingtime instanceof Timestamp) {
        closingtimenew = doc.data().closingtime.toDate();
      } else {
        closingtimenew = new Date(0);
      }

      lists.push({
        id: doc.id,
        num: doc.data().num,
        text: doc.data().text,
        imageurl: doc.data().imageURL,
        openingtime: openingtimenew,
        closingtime: closingtimenew,
      });
    });

    setCommandList(lists);

    return true;
  }

  async function setimagefunc(downloadURL: string): Promise<boolean> {
    await updateCommand(eki_id, shizi_id, {
      imageURL: downloadURL,
      openingtime: openingtime,
      closingtime: closingtime,
    });
    setimageurl(downloadURL);
    return true;
  }

  return (
    <>
      {commandList.length > 0 ? (
        <>
          　
          <div style={lineStyle}>
            {commandList.map((item2: any, index: any) => (
              <Button
                variant="outlined"
                key={item2.id}
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  minWidth: "auto",
                }}
                onClick={() => {
                  settext4(String(item2.num));
                  settext5(String(item2.text));
                  setShiziId(String(item2.id));
                  setimageurl(String(item2.imageurl));
                  setopeningtime(item2.openingtime);
                  setclosingtime(item2.closingtime);
                  setChangeType(true);
                  setChanging(false);
                }}
              >
                {item2.num}:{item2.text}
              </Button>
            ))}
          </div>
          <br />
          <Button
            variant="outlined"
            onClick={() => {
              settext4(String(commandList.length));
              settext5("");
              setShiziId("自動生成されます");
              setopeningtime(new Date(0));
              setclosingtime(new Date(0));
              setChangeType(false);
              setChanging(false);
            }}
          >
            {commandList.length}:追加する
          </Button>
        </>
      ) : (
        <>
          {explainText}
          <Button
            variant="outlined"
            onClick={() => {
              settext4(String(0));
              settext5("");
              setShiziId("自動生成されます");
              setopeningtime(new Date(0));
              setclosingtime(new Date(0));
              setChangeType(false);
              setChanging(false);
            }}
          >
            {commandList.length}:追加する
          </Button>
        </>
      )}
      <br />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="指令番号"
        value={text4}
        onChange={(e) => settext4(e.target.value)}
        disabled
      />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="指令内容"
        value={text5}
        onChange={(e) => settext5(e.target.value)}
      />
      <TextField
        fullWidth
        multiline
        maxRows={3}
        minRows={1}
        label="指令ID"
        value={shizi_id}
        onChange={(e) => setShiziId(e.target.value)}
        disabled
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {openingtime.toString()}
        <TimePicker
          label="開店時刻"
          value={dayjs(openingtime)}
          onChange={(newValue: any) => setopeningtime(newValue.toDate())}
        />
        {closingtime.toString()}
        <TimePicker
          label="閉店時刻"
          value={dayjs(closingtime)}
          onChange={(newValue: any) => setclosingtime(newValue.toDate())}
        />
      </LocalizationProvider>
      {text1 ? (
        <>
          {changeType ? (
            <>
              {" "}
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  setChanging(true);
                  await updateCommand(eki_id, shizi_id, {
                    num: Number(text4),
                    text: String(text5),
                    openingtime: openingtime,
                    closingtime: closingtime,
                  });

                  setTimeout(async () => {
                    await getDataCommand(eki_id);
                    setChanging(false);
                  }, 1000); // 1秒の遅延
                }}
                style={{ fontSize: 20 }}
                disabled={changing}
              >
                指令の変更
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  setChanging(true);
                  await deleteCommand(eki_id, shizi_id);
                  setTimeout(async () => {
                    await getDataCommand(eki_id);
                    setChanging(false);
                  }, 1000); // 1秒の遅延
                }}
                style={{ fontSize: 20 }}
                disabled={changing}
              >
                削除
              </Button>
              {shizi_id ? (
                <>
                  <ImageSetting
                    imageurl={imageurl}
                    // setimageurl={setimageurl}
                    setimagefunc={setimagefunc}
                    strageurl={
                      "YamanoteRoute/" + eki_id + "/Command/" + shizi_id + "/"
                    }
                  />
                  <DeveloperCommandImage
                    datas={datas}
                    text1={text1}
                    text2={text2}
                    eki_id={eki_id}
                    shizi_id={shizi_id}
                    changing={changing}
                    setChanging={setChanging}
                    changeCount={changeCount}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {" "}
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  setChanging(true);
                  await addCommand(eki_id, {
                    num: Number(text4),
                    text: String(text5),
                    openingtime: openingtime,
                    closingtime: closingtime,
                  });
                  setTimeout(async () => {
                    await getDataCommand(eki_id);
                    setChanging(false);
                  }, 1000); // 1秒の遅延
                }}
                style={{ fontSize: 20 }}
                disabled={changing}
              >
                指令の作成
              </Button>
            </>
          )}
          <br />
          山手線　{text2}駅から　徒歩20分圏内で楽しめる場所を教えて
          <br />
          ただし、下記両方の条件を満たすものを10ヶ所選んでください
          <br />・{text2}駅の方が
          {datas.routeList[(Number(text1) + 1) % 30].text}
          駅より距離が近いこと
          <br />・{text2}駅の方が
          {datas.routeList[(Number(text1) - 1 + 30) % 30].text}
          駅より距離が近いこと
          <br />
          <br />
          それぞれの場所についてどのくらい
          <br />
          {text2}駅の方が{datas.routeList[(Number(text1) + 1) % 30].text}駅・
          {datas.routeList[(Number(text1) - 1 + 30) % 30].text}
          駅より距離が近いか４０文字以内で教えてください
          <br />
          また、それぞれの場所について観光での魅力について１０文字以内で教えてください
          <br />
          <br />
          最後に場所名だけ一覧化してください
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default DeveloperCommand;
