import { onSnapshot } from "firebase/firestore";

import { createCheckoutSessions } from "../../common/firebaseHelper/FirebaseTransactionHelperStripe";

import getStripe from "./initializeStripe";

export async function createCheckoutSessionSubscription(
  uid: string,
  plan: string
) {
  //const firestore = firebase.firestore();
  if (!uid) {
    throw new Error("UID is required but not provided.");
  }

  let data;
  if (plan == "standard") {
    data = {
      price: "price_1Q24VaLAUxEuQidCfgDKeMmv",
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    };
  } else if (plan == "pro") {
    data = {
      price: "price_1NWS1iLAUxEuQidCmN9iC0Yc",
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    };
  } else {
    throw new Error(`Invalid plan type: ${plan}`);
  }

  const docRef = await createCheckoutSessions(uid, data);

  const unsub = onSnapshot(docRef, async (snap: any) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      //console.log(sessionId)
      const stripe = await getStripe();
      if (stripe) {
        //console.log(stripe)
        stripe.redirectToCheckout({ sessionId });
      }
    }
  });
}

export default createCheckoutSessionSubscription;
