import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, BookDatas } from "../../../common/types";
import { deleteEventParticipants } from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";
import { deleteUserPrivateBook } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";

import EventBlockShow from "./EventBlockShow";

type Props = {
  datas: Datas;
  bookDatas: BookDatas;
};

const MenuJoin: React.VFC<Props> = ({ datas, bookDatas }: Props) => {
  const navigate = useNavigate();
  const cellStyle: React.CSSProperties = {
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "14px",
    textAlign: "center", // 水平方向の中央揃え
    // width: "fit-content", // コンテンツ幅に合わせる
    marginLeft: "auto", // 左右中央に配置
    marginRight: "auto",
  };
  return (
    <div>
      <div
        style={{ textAlign: "center", backgroundColor: datas.bgColor.back05 }}
      >
        🔳 参加予定の一覧
      </div>
      <br />
      {datas.userInfo.isLogin ? (
        <>
          <Grid container spacing={3} padding={2}>
            {!bookDatas.eventJoinFutureList ||
            bookDatas.eventJoinFutureList.length === 0 ? (
              <div style={cellStyle}>イベントに参加していません。</div>
            ) : (
              <>
                {bookDatas.eventJoinFutureList.map((item: any, index: any) => (
                  <Grid item xs={6} sm={4} md={3} lg={3} key={item.id}>
                    <EventBlockShow
                      events={[
                        {
                          id: item.id,
                          title: item.title,
                          date: item.eventDateString,
                          location: item.eki + "駅",
                          image: item.gatheringPlace?.imageURL,
                          soldOut: false,
                          participants: [],
                          communityInfo: "公式イベント",
                          clickAction: "Event",
                        },
                      ]}
                    />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
          {/* 
          <table
            style={{
              width: datas.styletext.displayWidth,
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center", // テーブル内のコンテンツを中央揃え
            }}
          >
            <tbody>
              {!bookDatas.eventJoinFutureList ||
              bookDatas.eventJoinFutureList.length === 0 ? (
                <tr>
                  <td style={cellStyle}>イベントに参加していません。</td>
                </tr>
              ) : (
                <>
                  {bookDatas.eventJoinFutureList.map(
                    (item: any, index: any) => (
                      <tr key={index}>
                        <td style={cellStyle}>{item.title}</td>

                        <td style={cellStyle}>
                          {item.isDelete ? (
                            <Button
                              style={{
                                display: "inline-block",
                                padding: "10px 10px",
                                fontSize: "18px",
                                color: "#fff",
                                backgroundColor: "#d9534f", // 削除ボタンは赤色
                                textDecoration: "none",
                                borderRadius: "5px",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                transition: "background-color 0.3s ease",
                              }}
                              onClick={async () => {
                                try {
                                  // 削除処理の関数を await で呼び出し
                                  await deleteEventParticipants(
                                    item.id,
                                    datas.userInfo.userUid
                                  );
                                  await deleteUserPrivateBook(
                                    datas.userInfo.userUid,
                                    item.id
                                  );

                                  // 削除が完了したらページ遷移
                                  navigate("/Tsukaikata/");
                                } catch (error) {
                                  console.error(
                                    "削除処理でエラーが発生しました:",
                                    error
                                  );
                                  alert(
                                    "削除に失敗しました。もう一度お試しください。"
                                  );
                                }
                              }}
                            >
                              削除
                            </Button>
                          ) : (
                            <Button
                              style={{
                                display: "inline-block",
                                padding: "10px 10px",
                                fontSize: "18px",
                                color: "#fff",
                                backgroundColor: datas.bgColor.back06,
                                textDecoration: "none",
                                borderRadius: "5px",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                transition: "background-color 0.3s ease",
                              }}
                              onClick={() => {
                                navigate("/Event/" + item.id);
                              }}
                            >
                              詳細
                            </Button>
                          )}

                          {item.isDelete && (
                            <span
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                marginLeft: "10px",
                              }}
                            >
                              イベントは中止
                            </span>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </>
              )}
            </tbody>
          </table> */}
          <br />
          <div
            style={{
              textAlign: "center",
              backgroundColor: datas.bgColor.back05,
            }}
          >
            🔳 過去の参加一覧
          </div>
          <br />

          <Grid container spacing={3} padding={2}>
            {!bookDatas.eventJoinPastList ||
            bookDatas.eventJoinPastList.length === 0 ? (
              <div style={cellStyle}>イベントに参加していません。</div>
            ) : (
              <>
                {bookDatas.eventJoinPastList.map((item: any, index: any) => (
                  <Grid item xs={6} sm={4} md={3} lg={3} key={item.id}>
                    <EventBlockShow
                      events={[
                        {
                          id: item.id,
                          title: item.title,
                          date: item.eventDateString,
                          location: item.eki + "駅",
                          image: item.gatheringPlace?.imageURL,
                          soldOut: false,
                          participants: [],
                          communityInfo: "公式イベント",
                          clickAction: "Record",
                        },
                      ]}
                    />
                  </Grid>
                ))}
              </>
            )}
          </Grid>

          <br />
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <br />
          まずは
          <Button
            style={{
              display: "inline-block",
              padding: "10px 10px",
              fontSize: "18px",
              color: "#fff",
              backgroundColor: "#CC9900",
              textDecoration: "none",
              borderRadius: "5px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              bookDatas.setOpendMenu("signin");
            }}
          >
            ログイン
          </Button>
          しましょう
        </div>
      )}
    </div>
  );
};

export default MenuJoin;
