import { getAuth, onAuthStateChanged } from "firebase/auth";
import { otherApp } from "../firebaseHelper/firebase";

import {
  createUserSecretHistory,
  getUserSecretPointCanUse,
  pluspointUserSecret,
  minuspointUserSecret,
} from "../firebaseHelper/FirebaseTransactionHelperUserSecret";

export const pointAdd = async () => {
  onAuthStateChanged(getAuth(otherApp), async (user) => {
    if (user) {
      await user.getIdToken(true);
      const decodedToken = await user.getIdTokenResult();

      if (decodedToken.claims.stripeRole == "standard") {
        const nextpoint = await pluspointUserSecret(user.uid, 1000);
        const today = new Date();
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        createUserSecretHistory(user.uid, {
          uid: user.uid,
          point: nextpoint,
          pointchange: 1000,
          date: today,
          expirationDate: expirationDate,
          text1: "獲得",
          text2: "テストボタン押下",
          lonpaID: "none",
        });
        return nextpoint;
      } else if (decodedToken.claims.stripeRole == "pro") {
        const nextpoint = await pluspointUserSecret(user.uid, 5000);
        const today = new Date();
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        createUserSecretHistory(user.uid, {
          uid: user.uid,
          point: nextpoint,
          pointchange: 5000,
          date: today,
          expirationDate: expirationDate,
          text1: "獲得",
          text2: "テストボタン押下",
          eventID: "none",
        });
      } else {
        console.log("会員じゃないからポイントつかないよ");
        return false;
      }
    } else {
      console.log("会員じゃないからポイントつかないよ");
      return false;
    }
  });
};

export const pointSub = async (point: number, text: string, id: string) => {
  onAuthStateChanged(getAuth(otherApp), async (user) => {
    if (user) {
      await user.getIdToken(true);
      const decodedToken = await user.getIdTokenResult();

      if (
        decodedToken.claims.stripeRole == "standard" ||
        decodedToken.claims.stripeRole == "pro"
      ) {
        const nextpoint = await minuspointUserSecret(user.uid, point);
        if (nextpoint != false) {
          const today = new Date();
          const expirationDate = new Date();
          expirationDate.setMonth(expirationDate.getMonth() + 1);
          createUserSecretHistory(user.uid, {
            uid: user.uid,
            point: nextpoint,
            pointchange: point * -1,
            date: today,
            text1: "利用",
            text2: text,
            eventID: id,
          });
        }
        return nextpoint;
      } else {
        console.log("会員じゃないからポイント使えないよ");
        return false;
      }
    } else {
      console.log("会員じゃないからポイント使えないよ");
      return false;
    }
  });
};

export const pointSubParticipateEvent = async (id: string) => {
  pointSub(100, "散歩イベント参加", id);
};

interface excutable {
  isExcutable: boolean;
  isSubscribe: boolean;
  isMember: boolean;
  stripeRole: string;
  possession: number; //現在所持している量
  deficient: number; //足りない量
  required: number; //要求されている量
}

interface subscription {
  uid: string | null;
  email: string | null;
  isEmailVerified: boolean;
  isUser: boolean;
  isSubscribe: boolean;
  isStandard: boolean;
  isPro: boolean;
}

export function checkisExecutable(needpoint: number): Promise<excutable> {
  return new Promise((resolve) => {
    onAuthStateChanged(getAuth(otherApp), async (user) => {
      if (user) {
        await user.getIdToken(true);
        const decodedToken = await user.getIdTokenResult();
        if (
          decodedToken.claims.stripeRole == "standard" ||
          decodedToken.claims.stripeRole == "pro"
        ) {
          const nowpoint = await getUserSecretPointCanUse(user.uid);
          if (nowpoint >= needpoint) {
            resolve({
              isExcutable: true,
              isSubscribe: true,
              isMember: true,
              stripeRole: decodedToken.claims.stripeRole as string,
              possession: nowpoint,
              deficient: 0,
              required: needpoint,
            });
          } else {
            resolve({
              isExcutable: false,
              isSubscribe: true,
              isMember: true,
              stripeRole: decodedToken.claims.stripeRole as string,
              possession: nowpoint,
              deficient: needpoint - nowpoint,
              required: needpoint,
            });
          }
        } else {
          resolve({
            isExcutable: false,
            isSubscribe: false,
            isMember: true,
            stripeRole: "none",
            possession: 0,
            deficient: needpoint,
            required: needpoint,
          });
        }
      } else {
        resolve({
          isExcutable: false,
          isSubscribe: false,
          isMember: false,
          stripeRole: "none",
          possession: 0,
          deficient: needpoint,
          required: needpoint,
        });
      }
    });
  });
}

export function checkisSubscription(): Promise<subscription> {
  return new Promise((resolve) => {
    onAuthStateChanged(getAuth(otherApp), async (user) => {
      if (user) {
        await user.getIdToken(true);
        const decodedToken = await user.getIdTokenResult();
        if (decodedToken.claims.stripeRole == "standard") {
          resolve({
            uid: user.uid,
            email: user.email,
            isEmailVerified: user.emailVerified,
            isUser: true,
            isSubscribe: true,
            isStandard: true,
            isPro: false,
          });
        } else if (decodedToken.claims.stripeRole == "pro") {
          resolve({
            uid: user.uid,
            email: user.email,
            isEmailVerified: user.emailVerified,
            isUser: true,
            isSubscribe: true,
            isStandard: false,
            isPro: true,
          });
        } else {
          resolve({
            uid: user.uid,
            email: user.email,
            isEmailVerified: user.emailVerified,
            isUser: true,
            isSubscribe: false,
            isStandard: false,
            isPro: false,
          });
        }
      } else {
        resolve({
          uid: null,
          email: null,
          isEmailVerified: false,
          isUser: false,
          isSubscribe: false,
          isStandard: false,
          isPro: false,
        });
      }
    });
  });
}
