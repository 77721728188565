import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Container,
  Box,
  Card,
  CardContent,
  Divider,
  CardHeader,
  IconButton,
  Checkbox,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";

// import { useStyles } from "../../Styles";
import moment from "moment";
import SettingsIcon from "@mui/icons-material/Settings";

type Props = {
  bgColor: any;
  title: any;
  hint: any;
  menu: any;
  both: any;
  subscribe: any;
  isusable: any;
};

const Test: React.VFC<Props> = ({
  bgColor,
  title,
  hint,
  menu,
  both,
  subscribe,
  isusable,
}: Props) => {
  const tdStyle = {
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "5px",
    paddingBottom: "5px",
  };
  const tableStyle = {
    width: "100%",
  };
  const trStyle = {};

  const borderStyle = [
    {
      border: "2px solid " + bgColor.text02.fontColor,
      borderRadius: "5px 5px 5px 5px",
      padding: "5px 10px",
    },
    {
      border: "2px solid " + bgColor.text03.fontColor,
      borderRadius: "5px 5px 5px 5px",
      padding: "5px 10px",
    },
  ];

  const titlestyle = {
    fontSize: "17px",
    fontWeight: "bold",
    padding: "2px 2px 2px 0px",
    color: bgColor.text01.fontColor,
  };

  const hintstyle = {
    fontSize: "13px",
    fontWeight: "bold",
    padding: "2px 2px 2px 0px",
    color: bgColor.text02.fontColor,
  };

  const menushow = {
    fontSize: "15px",
    padding: "2px 2px 2px 2px",
    marginLeft: "10px",
    color: bgColor.text03.fontColor,
  };

  const menudisable = {
    fontSize: "15px",
    padding: "2px 2px 2px 2px",
    marginLeft: "10px",
    color: bgColor.text02.fontColor,
  };
  return (
    <table style={tableStyle}>
      <tbody>
        <tr style={trStyle}>
          <td style={tdStyle}>
            <div style={borderStyle[+isusable]}>
              <div style={titlestyle}>
                <Checkbox
                  //{...label}
                  icon={<CircleIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  disabled
                  checked={isusable}
                  style={{
                    color:
                      isusable == 0
                        ? bgColor.text02.fontColor
                        : bgColor.text03.fontColor,
                  }}
                />
                {title}{" "}
              </div>
              {hint ? (
                <div style={hintstyle} key={"a"}>
                  {hint}{" "}
                </div>
              ) : (
                <></>
              )}
              {isusable ? (
                <>
                  {both.map((item: any, index: any) => (
                    <>
                      <Button
                        style={menushow}
                        onClick={() => {
                          item.excute();
                        }}
                      >
                        {item.text}
                      </Button>
                      <br />
                    </>
                  ))}
                  {menu.map((item: any, index: any) => (
                    <>
                      <Button
                        style={menushow}
                        onClick={() => {
                          item.excute();
                        }}
                      >
                        {item.text}
                      </Button>
                      <br />
                    </>
                  ))}
                </>
              ) : (
                <>
                  {both.map((item: any, index: any) => (
                    <>
                      <Button
                        style={menushow}
                        onClick={() => {
                          item.excute();
                        }}
                      >
                        {item.text}
                      </Button>
                      <br />
                    </>
                  ))}
                  {subscribe.map((item: any, index: any) => (
                    <>
                      <Button
                        style={menushow}
                        onClick={() => {
                          item.excute();
                        }}
                      >
                        {item.text}
                      </Button>
                      <br />
                    </>
                  ))}

                  {/* {menu.map((item: any, index: any) => (
                    <>
                      <Button style={menudisable} disabled>
                        + {item.text}
                      </Button>
                      <br />
                    </>
                  ))} */}
                </>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default Test;
