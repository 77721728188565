import { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { pointtableTypeA } from "../../../common/pointHelper/pointTable";
import {
  checkisExecutable,
  pointSubParticipateEvent,
} from "../../../common/pointHelper/PointHelper";

import { useNavigate } from "react-router-dom";

type Props = {
  open: boolean;
  setOpen: any;
  logtext: string;
};

const PointModal: React.VFC<Props> = ({ open, setOpen, logtext }: Props) => {
  // アイデア作成モーダルのコンポーネント
  const navigate = useNavigate();

  type executableType = {
    possession: number; // 現在の所持ポイント数
    required: number; // 実行に必要なポイント数
    deficient: number; // 不足しているポイント数
    isSubscribe: boolean; // サブスク登録済みかどうか
    isExcutable: boolean; // 実行可能かどうか
    isMember: boolean; // メンバー登録済みかどうか
  };

  // ポイント使用チェックの状態管理
  const [isUsePointCheck, setIsUsePointCheck] = useState(false);

  // AI入力フィールドの初期値設定
  const [aiInput, setAiInput] = useState(
    "テスト用です．使わないで\nお金がかかるけど\n何も起きません"
  );

  // AI出力フィールドの初期値設定
  const [aiOutput, setAiOutput] = useState("回答");

  // 実行可能状態の管理
  const [executable, setExecutable] = useState({
    possession: 0, // 現在の所持ポイント数
    required: 100, // 実行に必要なポイント数
    deficient: 100, // 不足しているポイント数
    isSubscribe: false, // サブスク登録済みかどうか
    isExcutable: false, // 実行可能かどうか
    isMember: false, // メンバー登録済みかどうか
  });

  // 実行目的の管理
  const [purpose, setPurpose] = useState("");

  // ポイント後処理
  const functionAfterPay = async () => {
    console.log("ポイント使用しました");
  };

  // モーダルを閉じる関数
  const handleCloseinside = () => {
    setOpen(false);
  };

  // 実行可能状態をチェックする関数
  async function checkAble() {
    const executable = await checkisExecutable(100); // ポイントチェック
    setExecutable(executable); // 結果を設定
    setPurpose("ポイント使用"); // 目的を設定
    setIsUsePointCheck(true); // ポイント使用確認を有効化
  }

  // ジョブを実行する関数
  async function ExcuteJob() {
    pointSubParticipateEvent(logtext); // ポイント使用処理
    functionAfterPay(); // ポイント使用後処理
    setIsUsePointCheck(false); // チェック解除
  }

  return (
    // モーダルの定義
    <Modal
      aria-labelledby="transition-modal-title" // モーダルのタイトル設定
      aria-describedby="transition-modal-description" // モーダルの説明設定
      //className={classes.modal} // モーダルのスタイル適用
      open={open} // モーダルの開閉状態をpropsから取得
      onClose={handleCloseinside} // モーダル外クリック時の処理
      closeAfterTransition // フェードアニメーション完了後にDOM要素を削除
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        {/* // フェードイン・フェードアウトのアニメーション効果 */}
        <div
          style={{
            border: "0px solid #000",
            backgroundColor: "#eeeeee",
            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)", // boxShadowは数値だけではなく、影の指定が必要
            overflow: "auto",
            padding: "20px", // 内側の余白を追加
            borderRadius: "10px", // 角丸にする
          }}
        >
          {/* // モーダルの内容を囲むスタイルクラス */}
          {isUsePointCheck ? (
            // ポイント利用の確認画面
            <Grid item xs={12} style={{ backgroundColor: "#eee" }}>
              <h2 id="transition-modal-title">
                {purpose}
                {/* // 実行目的のタイトル表示 */}
              </h2>
              <div>
                <br />
                現在のポイント：{executable.possession}
                <br />
                {executable.required}point消費して実行しますか？
                <br />
                {executable.isSubscribe ? ( // サブスク登録状況の確認
                  <>
                    {executable.isExcutable ? ( // ポイントが実行可能な場合
                      <>
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            ExcuteJob(); // ジョブ実行関数呼び出し
                            setIsUsePointCheck(false); // ポイントチェック解除
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          実行
                        </Button>
                      </>
                    ) : (
                      // ポイント不足の場合
                      <>
                        {executable.deficient}ポイント不足
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            setIsUsePointCheck(false);
                            navigate("/Payment/"); // 支払いページへ移動
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          ポイント確認
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {executable.isMember ? ( // メンバーの場合、サブスク登録を促す
                      <>
                        サブスク登録しましょう
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            setIsUsePointCheck(false);
                            navigate("/Subscription/"); // サブスク登録ページへ移動
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          サブスク登録
                        </Button>
                      </>
                    ) : (
                      // メンバーでない場合、メンバー登録を促す
                      <>
                        メンバ登録しましょう
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            setIsUsePointCheck(false);
                            navigate("/Top/"); // 登録ページへ移動
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          メンバ登録
                        </Button>
                      </>
                    )}
                  </>
                )}
                .........
                <Button
                  color="primary"
                  onClick={() => {
                    setIsUsePointCheck(false); // 戻るボタンの処理
                  }}
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  戻る
                </Button>
                <br />
                内訳
                {pointtableTypeA}
                {/* // ポイントの内訳表示 */}
              </div>
            </Grid>
          ) : (
            // 商品の紹介表示
            <Grid item xs={12} style={{ backgroundColor: "#eee" }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                  fontWeight="bold"
                >
                  <Checkbox
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked={true}
                  />
                  ポイントの使用
                </Typography>
                <br />
                <TextField
                  fullWidth
                  multiline
                  maxRows={3}
                  minRows={1}
                  label="説明"
                  value={aiInput}
                  onChange={(e) => setAiInput(e.target.value)} // 入力フィールド
                />
                <br />

                <Button
                  style={{
                    display: "inline-block",
                    padding: "10px 10px",
                    fontSize: "18px",
                    color: "#fff",
                    backgroundColor: "#b2d8b2", // 薄い緑色に変更
                    textDecoration: "none",
                    borderRadius: "5px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease",
                    width: "100%",
                    cursor: "pointer", // マウスホバー時のカーソルをポインターに変更
                  }}
                  onClick={() => {
                    checkAble(); // 実行可能か確認
                  }}
                >
                  散歩ポイント消費(100point)
                </Button>

                <Button
                  style={{
                    display: "inline-block",
                    padding: "10px 10px",
                    fontSize: "18px",
                    color: "#fff",
                    backgroundColor: "#b2d8b2", // 薄い緑色に変更
                    textDecoration: "none",
                    borderRadius: "5px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease",
                    width: "100%",
                    cursor: "pointer", // マウスホバー時のカーソルをポインターに変更
                  }}
                  onClick={() => {
                    window.open(
                      "https://buy.stripe.com/6oEeVW8yk9EMcG44gl",
                      "_blank"
                    );
                  }}
                >
                  散歩ポイント購入
                </Button>
              </Box>
            </Grid>
          )}
        </div>
      </Fade>
    </Modal>
  );
};
export default PointModal;
