import { useState, useEffect, useMemo } from "react";

import { marked } from "marked";
import { useNavigate } from "react-router-dom";
import { Datas } from "../../../common/types";
import { useParams } from "react-router-dom";

import {
  getEvent,
  getEventRouteImages,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import { getEventRoutes } from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import moment from "moment";

import RecordView from "./RecordView";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

import LodingModal from "../../Modal/LodingModal/LodingModal";

type Props = {
  datas: Datas;
};

// Markedのオプションを設定
marked.setOptions({
  breaks: true, // 改行を有効にする
  gfm: true, // GitHub Flavored Markdownを有効にする（リストなどの構文の互換性向上）
});

const Record: React.FC<Props> = ({ datas }) => {
  let { recordid } = useParams();
  const [recordData, setRecordData] = useState({
    id: "",
    title: "",
    comment: "",
    eki: "",
    ekiID: "",
    eventDate: new Date(0),
    eventDateString: "",
    gatheringPlace: { id: "", imageURL: "", num: "", text: "" },
  });

  const [eventDateString, setEventDateString] = useState("");

  const [EventRoute, setEventRoute] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const [opendMenu, setOpendMenu] = useState("show");

  const [isParamReady, setIsParamReady] = useState(false);
  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  const navigate = useNavigate();

  const parseAsMoment = (dateTimeStr: any) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  useEffect(() => {
    const f = async () => {
      console.log("ue_Record");
      setLodingmodalopen(true);

      //データを取得
      let doc = await getEvent(recordid);

      //イベントタイトルを設定

      //イベントの日程を設定
      if (doc && doc.data() && doc.data().title) {
        //イベントの日程を設定
        // console.log(doc.data());
        if (doc.data().eventDate) {
          const eventdatestring = parseAsMoment(doc.data().eventDate.toDate())
            .format("YYYY/MM/DD HH:mm")
            .toString();

          //コメントをマークダウンに
          const markedhtmlcomment = await marked.parse(
            doc.data().comment.replace(/\\n/g, "\n")
          );
          setRecordData({
            id: doc.id,
            title: doc.data().title,
            comment: markedhtmlcomment,
            eki: doc.data().eki,
            ekiID: doc.data().ekiID,
            eventDate: doc.data().eventDate.toDate(),
            eventDateString: eventdatestring,
            gatheringPlace: doc.data().gatheringPlace,
          });
        } else {
          setEventDateString("イベントは中止になりました:id01");
          navigate("/Book");
        }

        //イベントルート確認
        const querySnapshot2 = await getEventRoutes(recordid);
        let lists4: any = [];

        for (const doc of querySnapshot2.docs) {
          // console.log(doc.id);
          let lists5: any = [];

          if (doc.data().EventRouteImageCount > 0) {
            //イベントルート確認
            const querySnapshot3 = await getEventRouteImages(recordid, doc.id);

            querySnapshot3.forEach((doc: any) => {
              lists5.push({
                id: doc.id,
                imageURL: doc.data().imageURL,
              });
            });
          }

          lists4.push({
            id: doc.id,
            commandId: doc.data().id,
            num: doc.data().num,
            text: doc.data().text,
            EventRouteImageCount: doc.data().EventRouteImageCount,
            Images: lists5,
          });
        }
        setEventRoute(lists4);
      } else {
        alert("中止になりました:id02");
      }

      setLodingmodalopen(false);
      setIsParamReady(true);
    };
    f();
  }, [changeCount]);

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };

  const recordDatas = useMemo(() => {
    return {
      get opendMenu() {
        return opendMenu;
      },
      setOpendMenu,
      get recordData() {
        return {
          ...recordData,
        };
      },

      get EventRoute() {
        return EventRoute;
      },
    };
  }, [opendMenu, recordData, EventRoute]);

  return (
    <div>
      {isParamReady ? (
        <>
          <Navbar datas={datas} />

          <div
            style={
              datas.styletext.windowWidth > 1200
                ? styles1.container
                : styles2.container
            }
          >
            <RecordView datas={datas} recordDatas={recordDatas} />
          </div>

          <Footer datas={datas} />
        </>
      ) : (
        <></>
      )}

      <LodingModal open={lodingmodalopen} />
    </div>
  );
};

export default Record;
