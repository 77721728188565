import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  memo,
  Fragment,
} from "react";

import { marked } from "marked";

import { get3commands } from "../../../common/firebaseHelper/FirebaseTransactionHelper";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";
import { useQRCode } from "next-qrcode";

import { otherApp } from "../../../common/firebaseHelper/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { Timestamp } from "firebase/firestore"; // FirestoreのTimestamp型をインポート

import {
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Button,
} from "@mui/material";

import {
  getEvent,
  getEventBBSs,
  addEventBBS,
  getEventParticipants,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import {
  setUserPrivateBook,
  getUserPrivateBooks,
  deleteUserPrivateBook,
  // getUserPrivatePlan,
  // setUserPrivatePlan,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPrivate";

import { getUserPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import {
  getEvents,
  setEventParticipants,
  deleteEventParticipants,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperEvent";

import { getListOfUsersPublic } from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import moment from "moment";

import GatyaresultView from "./GatyaresultView";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

type Props = {
  datas: Datas;
};

// Markedのオプションを設定
marked.setOptions({
  breaks: true, // 改行を有効にする
  gfm: true, // GitHub Flavored Markdownを有効にする（リストなどの構文の互換性向上）
});

const Gatyaresult: React.FC<Props> = ({ datas }) => {
  let { eventid } = useParams();
  const [content, setContent] = useState<string>("");
  const [eventData, setEventData] = useState({
    id: "",
    title: "",
    comment: "",
    eki: "",
    eventDate: new Date(0),
    eventDateString: "",
    gatheringPlace: { id: "", imageURL: "", num: "", text: "" },
  });
  const [changeCount, setChangeCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const f = async () => {
      console.log("ue_Event");
    };
    f();
  }, [changeCount]);

  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0px 0px 0px 0px",
    },
  };

  return (
    <>
      <Navbar datas={datas} />

      <div
        style={
          datas.styletext.windowWidth > 1200
            ? styles1.container
            : styles2.container
        }
      >
        <GatyaresultView datas={datas} />
      </div>

      <Footer datas={datas} />
    </>
  );
};

export default Gatyaresult;
