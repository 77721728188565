import { useState } from "react";
// import { useStyles } from "../Styles";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Button, Link, Paper } from "@mui/material";

import { Datas, PaymentDatas } from "../../../common/types";
type Props = {
  datas: Datas;
  paymentDatas: PaymentDatas;
};

const PaymentCancell: React.VFC<Props> = ({ datas, paymentDatas }: Props) => {
  //   const classes = useStyles();
  // const navigate = useNavigate();

  // const [lodingmodalopen, setLodingmodalopen] = useState(true);

  // const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const cellStyle: React.CSSProperties = {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center",
  };

  return (
    <div
      style={{
        backgroundColor: datas.bgColor.back01,
        color: datas.bgColor.text01.fontColor,
      }}
    >
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h2 id="transition-modal-title">プラン変更</h2>
              下記設定用リンクよりManage Subscriptionへ遷移し
              <br />
              サブスク登録時に記入いただいたメールアドレスにて
              <br />
              サブスク解約手続きが可能です。
              <br />
              <br />
              注意：解約までにお支払いいただいた金額の払い戻しはできません。
              <br />
              <br />
              プラン変更をご希望の場合、現プラン解約後に別プランへ登録お願いします
              <br />
              <br />
              <br />
              <table style={{ margin: "0 auto" }}>
                <tbody>
                  <tr style={{ borderTop: "solid 3px #CCC" }}>
                    <td style={cellStyle}>プラン</td>
                    <td style={cellStyle}>料金</td>
                    <td style={cellStyle}>加入日</td>
                    <td style={cellStyle}>キャンセル日</td>
                  </tr>

                  {datas.UserPaymentDatas.subscriptionsdatas.map(
                    (item, index) => (
                      <tr style={{ borderTop: "solid 3px #CCC" }}>
                        <td style={cellStyle}>{item.role}</td>
                        <td style={cellStyle}>{item.amount}</td>
                        <td style={cellStyle}>{item.created}</td>
                        <td style={cellStyle}>{item.canceld}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <br />
              <br />
              アカウント情報:
              {userEmail} <br />
              <br />
              <Link
                color="inherit"
                href="https://billing.stripe.com/p/login/3cs8z0c1b6N59cA4gg"
                target="_blank"
                rel="noopener noreferrer"
              >
                解約リンクはこちら
              </Link>
              <br />
              <br />
              <Button
                disabled={false}
                size="large"
                variant="contained"
                style={{ fontSize: 15, backgroundColor: datas.bgColor.back04 }}
                onClick={() => {
                  paymentDatas.setOpendMenu("history");
                }}
              >
                ステータス確認
              </Button>
              <br />
              <br />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};
export default PaymentCancell;
