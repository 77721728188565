import PaymentHistory from "./PaymentHistory";

import { Datas, PaymentDatas } from "../../../common/types";
import PaymentCancell from "./PaymentCancell";

import PaymentSubscription from "./PaymentSubscription";
import PaymentSinglePurchase from "./PaymentSinglePurchase";

type Props = {
  datas: Datas;
  paymentDatas: PaymentDatas;
};

const EventView: React.VFC<Props> = ({ datas, paymentDatas }: Props) => {
  return (
    <>
      {paymentDatas.opendMenu === "history" ? (
        <PaymentHistory datas={datas} paymentDatas={paymentDatas} />
      ) : paymentDatas.opendMenu === "cancell" ? (
        <PaymentCancell datas={datas} paymentDatas={paymentDatas} />
      ) : paymentDatas.opendMenu === "subscription" ? (
        <PaymentSubscription datas={datas} paymentDatas={paymentDatas} />
      ) : paymentDatas.opendMenu === "singlepurchase" ? (
        <PaymentSinglePurchase datas={datas} paymentDatas={paymentDatas} />
      ) : (
        <div>ここは表示されないはず</div>
      )}
    </>
  );
};
export default EventView;
