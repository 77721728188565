import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Datas, Route } from "../../../common/types";
import { useNavigate } from "react-router-dom";

import { otherApp } from "../../../common/firebaseHelper/firebase";

import { getDatas } from "../../../common/firebaseHelper/FirebaseTransactionHelper";

import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  CardHeader,
  Link,
  IconButton,
} from "@mui/material";

import {
  getAuth,
  updateProfile,
  updatePassword,
  signOut,
  onAuthStateChanged,
  getRedirectResult,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";

// import Useravatar from "./Userpage/Useravatar";
import { Controller, useForm } from "react-hook-form";

import CachedIcon from "@mui/icons-material/Cached";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import SettingsIcon from "@mui/icons-material/Settings";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  VALIDATION_EMAIL,
  VALIDATION_ERR_REQUIRED,
  VALIDAtION_INVALID_CHARACTER,
  VALIDATION_LONG_MAX_LENGTH,
  VALIDATION_PASSWORD_MAX_LENGTH,
  VALIDATION_PASSWORD_MIN_LENGTH,
  VALIDATION_USERNAME_MIN_LENGTH,
} from "../../../common/validation";

import { NORMAL_CHARACTER } from "../../../common/regex";

import {
  getUserPublic,
  // setUserPublic,
  updateOrSetUserPublic,
} from "../../../common/firebaseHelper/FirebaseTransactionHelperUserPublic";

import Useravatar from "./Useravatar";

// type Props = {
//   datas: Datas;
//   setDatas: Dispatch<SetStateAction<Datas>>;
//   routeList: Route[];
// };
const centerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // height: "100vh", //画面の高さいっぱいに表示する場合
};

// const Tsukaikata: React.FC<Props> = ({ datas, setDatas, routeList }) => {
const Userpage = (props) => {
  const navigate = useNavigate();
  const [uid, setUid] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [imgurl, setImgurl] = useState("");
  const [upload, setupload] = useState(0);
  const [errormessage, setErrorMessage] = useState("");
  // const [imageurl, setAvatar] = useState("");
  const [lodingmodalopen, setLodingmodalopen] = useState(true);
  const [emailverified, setEmailVerified] = useState(false);
  //画像投稿時のエラー内容を格納
  const [uploaderror, setUploaderror] = useState("");
  const [help, sethelp] = useState(false);

  const [isLoding, setIsLoding] = useState(true);

  const schema = yup.object({
    username: yup
      .string()
      .required(VALIDATION_ERR_REQUIRED)
      .min(4, VALIDATION_USERNAME_MIN_LENGTH)
      .max(64, VALIDATION_LONG_MAX_LENGTH)
      .matches(NORMAL_CHARACTER, VALIDAtION_INVALID_CHARACTER),
  });

  const {
    control,
    handleSubmit: handleFormSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    console.log("uE_Userpage");
    const f = async () => {
      const usercheck = onAuthStateChanged(getAuth(otherApp), async (user) => {
        if (user) {
          if (typeof user.uid !== "undefined") {
            //console.log(user.uid);
            setUid(user.uid);
            setEmail(user.email);
            setValue("email", user.email);
            let doc = await getUserPublic(user.uid);
            let dataexsitflag = false;

            if (typeof doc.data() !== "undefined") {
              if (typeof doc.data().name !== "undefined") {
                dataexsitflag = true;
              } else if (typeof doc.data().imageurl !== "undefined") {
                dataexsitflag = true;
              } else {
                dataexsitflag = false;
              }
            } else {
              dataexsitflag = false;
            }

            if (dataexsitflag) {
              setName(doc.data().name);
              setImgurl(doc.data().imageurl);
            } else {
              // const num = Math.floor(Math.random() * 4);
              // props.setAvatar({
              //   img: picurl[num],
              //   name: "名無し",
              // });
              // setName(user.uid.substr(0, 4));
              // updateProfile(user, {
              //   displayName: user.uid.substr(0, 4),
              // });
              // setUserPublic(user.uid, {
              //   name: user.uid.substr(0, 4),
              //   imageurl: picurl[num],
              // });
              // props.setChangeImg(props.changeImg + 1);
            }

            setEmailVerified(getAuth(otherApp).currentUser.emailVerified);

            setLodingmodalopen(false);
            setIsLoding(false);
          } else {
            window.scrollTo(0, 0);
            props.incrementChangeCount();
            setIsLoding(false);
            // navigate("/Signin");
          }
        } else {
          setIsLoding(false);
          // getRedirectResult(getAuth())
          //   .then((result) => {
          //     // console.log(result);
          //   })
          //   .catch((error) => {
          //   });
          window.scrollTo(0, 0);
          props.incrementChangeCount();
          // navigate("/Signin");
          setLodingmodalopen(false);
        }
      });

      return () => usercheck();
    };
    f();
  }, [upload]);

  const handleLogout = () => {
    signOut(getAuth(otherApp))
      .then(() => {
        console.log("ログアウトしました");
        props.datas.initUserIsNotLogin();
        window.scrollTo(0, 0);
        props.incrementChangeCount();
        navigate("/Book");
      })
      .catch((error) => {
        // console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
  };

  async function handleSubmit() {
    // console.log('通過')
    let user = getAuth(otherApp).currentUser;
    if (user.displayName != name) {
      if (user) {
        await updateProfile(user, {
          displayName: name,
          email: email,
        })
          .then(() => {
            setupload(upload + 1);
            setErrorMessage("変更しました");
            alert(
              "名前を変更しました。※イベントでの表示名を変える場合はキャンセルして参加し直す必要があります。"
            );
          })
          .catch((error) => {
            // An error occurred
            // ...
            alert("名前を変更に失敗しました。時間を空けて再度実施してください");
          });
      }
      await updateOrSetUserPublic(user.uid, {
        name: name,
        // imageurl: props.avatar.img,
      });
    } else {
      // console.log(user.displayName ,name)
    }
  }

  return (
    <div style={centerStyle}>
      {isLoding ? (
        <></>
      ) : (
        <>
          {/* {uid} */}
          {name ? (
            <></>
          ) : (
            <div
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "red",
                border: "2px solid red",
                padding: "10px",
                backgroundColor: "yellow",
                textAlign: "center",
                margin: "20px 0",
              }}
            >
              表示名は予約前に登録する必要があります。
              <br />
              下記入力し<CachedIcon sx={{ color: "#6495ed" }}></CachedIcon>
              ボタン押下ください
            </div>
          )}
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card>
                  <CardHeader
                    title="あなたのプロフィール"
                    action={
                      <IconButton
                        aria-label="help"
                        onClick={() => {
                          sethelp(!help);
                        }}
                      >
                        <SettingsIcon sx={{ color: "#808080" }} />
                      </IconButton>
                    }
                  />
                  <Divider />
                  <CardContent>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Useravatar
                        user={getAuth(otherApp).currentUser}
                        imageurl={imgurl}
                        setimageurl={setImgurl}
                        setOpen={setLodingmodalopen}
                      />
                      <Controller
                        control={control}
                        name="username"
                        render={({ field: { onChange } }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "100%",
                            }}
                          >
                            <TextField
                              label="表示名"
                              name="Name"
                              value={name}
                              variant="standard"
                              fullWidth
                              margin="dense"
                              onChange={(e) => {
                                onChange(e);
                                setName(e.target.value);
                              }}
                              sx={{
                                input: {
                                  color: "#000000",
                                },
                              }}
                              style={{
                                border: "#000000",
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "#000000",
                                }, // ここに希望の色を指定
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleFormSubmit(handleSubmit)}
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <CachedIcon
                                        sx={{ color: "#6495ed" }}
                                      ></CachedIcon>
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        )}
                      />
                      <Typography>{errors.username?.message}</Typography>

                      {emailverified ? (
                        <>
                          <TextField
                            label="メールアドレス"
                            name="email"
                            margin="normal"
                            value={email}
                            variant="standard"
                            fullWidth
                            disabled
                            sx={{
                              input: {
                                color: "#000000",
                                disabled: "#000000",
                              },
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                            style={{
                              border: "#000000",
                              // disabled: "#000000",
                            }}
                            InputLabelProps={{
                              style: { color: "#000000" }, // ラベルの色を指定
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CheckCircleIcon sx={{ color: "#008000" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <TextField
                            label="Email Address"
                            name="email"
                            margin="normal"
                            value={email}
                            variant="standard"
                            fullWidth
                            disabled
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ErrorIcon sx={{ color: "#DD0000" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {/* <Button onClick={() => VerifyEmail()}>
                        メール認証を実施する
                      </Button> */}
                        </>
                      )}
                    </Box>
                  </CardContent>
                  <Divider />
                </Card>
              </Grid>
              {help ? (
                <>
                  <Grid item xs={12} md={12}>
                    <Card
                      style={{
                        backgroundColor: "props.datas.bgColor.back01",
                        color: "#000000",
                      }}
                    >
                      <CardHeader title="プロフィールの変更方法" />
                      <Divider />
                      <CardContent>
                        <Typography>
                          <u>🔳 ユーザ名を変えたい</u>
                          <br />
                          ⇒上記の表示名を変更して、
                          <CachedIcon sx={{ color: "#6495ed" }} />
                          を押下で設定できます。
                        </Typography>
                        <br />
                        <Typography>
                          <u>🔳 ログアウトしたい</u>
                          <br />⇒{" "}
                          <Button
                            variant="outlined"
                            onClick={() => {
                              handleLogout();
                            }}
                          >
                            ログアウト
                          </Button>
                        </Typography>
                        <br />
                        <Typography>
                          <u>
                            🔳 登録したメールの最後に
                            <CheckCircleIcon sx={{ color: "#008000" }} />
                            もしくは <ErrorIcon sx={{ color: "#DD0000" }} />
                            がある
                          </u>
                          <br />
                          ⇒メール認証の実施有無を示すマークです。
                          <br />
                          <CheckCircleIcon sx={{ color: "#008000" }} />
                          はメール認証済みで、
                          <ErrorIcon sx={{ color: "#DD0000" }} />
                          は未認証です。
                          <br />
                          メール認証は「メール認証を実施する」を押下で実施できます。
                        </Typography>
                        <br />
                        <Typography>
                          <u> 🔳 パスワードを変えたい</u>
                          <br />
                          ⇒パスワードを変更したい場合は、
                          <Link
                            href="#"
                            color="#6495ed"
                            // onClick={handleRePasswordSet}
                          >
                            こちら
                          </Link>
                          ※準備中
                        </Typography>
                        <div style={{ color: props.datas.bgColor.back01 }}>
                          {uid}
                        </div>
                        <br />
                      </CardContent>
                      <Divider />
                    </Card>
                  </Grid>
                  <br />
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Container>
        </>
      )}
    </div>
  );
};

export default Userpage;
