import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Button, Link, Paper } from "@mui/material";
import { pointAdd } from "../../../common/pointHelper/PointHelper";
import { Datas, PaymentDatas } from "../../../common/types";
type Props = {
  datas: Datas;
  paymentDatas: PaymentDatas;
};

const PaymentHistory: React.VFC<Props> = ({ datas, paymentDatas }: Props) => {
  const navigate = useNavigate();
  const cellStyle: React.CSSProperties = {
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center",
    fontSize: "14px",
  };

  return (
    <div
      style={{
        backgroundColor: datas.bgColor.back01,
        color: datas.bgColor.text01.fontColor,
      }}
    >
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Grid container spacing={3}>
            {datas.UserPaymentDatas.userIsStandard ||
            datas.UserPaymentDatas.userIsPro ? (
              <>
                <Grid item xs={12}>
                  <h2>ステータス確認</h2>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    有料P：{datas.UserPaymentDatas.userPoint} point 所持
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "blue",
                    }}
                  >
                    無料P：{datas.UserPaymentDatas.userFreePoint} point 所持
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    ポイント 支払い履歴
                  </div>
                  <br />
                  <table style={{ margin: "0 auto" }}>
                    <tbody>
                      <tr style={{ borderTop: "solid 3px #CCC" }}>
                        <td style={cellStyle}>種別</td>
                        <td style={cellStyle}>内容</td>
                        <td style={cellStyle}>ポイント</td>
                        <td style={cellStyle}>日付</td>
                        <td style={cellStyle}>期限</td>
                        <td style={cellStyle}>リンク</td>
                      </tr>

                      {datas.UserPaymentDatas.userPointHistory.map(
                        (item, index) => (
                          <tr style={{ borderTop: "solid 3px #CCC" }}>
                            <td style={cellStyle}>{item.text1}</td>
                            <td style={cellStyle}>{item.text2}</td>

                            <td style={cellStyle}>{item.pointchange}</td>
                            <td style={cellStyle}>{item.date}</td>
                            <td style={cellStyle}>{item.expirationDate}</td>
                            <td style={cellStyle}>
                              {item.lonpaID != "none" ? (
                                <>
                                  <Link
                                    color="inherit"
                                    onClick={() =>
                                      navigate("/Lonpa/" + item.lonpaID)
                                    }
                                  >
                                    リンク
                                  </Link>
                                </>
                              ) : (
                                <>-</>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  <br />
                </Grid>

                <Grid item xs={12}>
                  <br />

                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    {datas.UserPaymentDatas.userIsStandard ? (
                      <>現在 Lamact Standardプラン加入中</>
                    ) : (
                      <>現在 Lamact Proプラン加入中</>
                    )}

                    <br />
                    <Button
                      disabled={false}
                      size="large"
                      variant="contained"
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        backgroundColor: datas.bgColor.back04,
                      }}
                      onClick={() => {
                        paymentDatas.setOpendMenu("cancell");
                      }}
                    >
                      領収書・プラン変更・解約
                    </Button>
                  </div>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <br />
                  <div
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    現金 支払い履歴
                  </div>
                  <br />
                  <table style={{ margin: "0 auto" }}>
                    <tbody>
                      <tr style={{ borderTop: "solid 3px #CCC" }}>
                        <td style={cellStyle}>支払日</td>
                        <td style={cellStyle}>金額</td>
                      </tr>

                      {datas.UserPaymentDatas.paymentdatas.map(
                        (item, index) => (
                          <tr style={{ borderTop: "solid 3px #CCC" }}>
                            <td style={cellStyle}>{item.created}</td>
                            <td style={cellStyle}>{item.amount}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>

                  <br />
                </Grid>
              </>
            ) : (
              <>
                {" "}
                <Grid item xs={12}>
                  <h2 id="transition-modal-title">サブスクリプションなし</h2>
                  <Grid item xs={12}>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: 20,
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      有料P：{datas.UserPaymentDatas.userPoint} point 所持
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: 20,
                        fontWeight: "bold",
                        color: "blue",
                      }}
                    >
                      無料P：{datas.UserPaymentDatas.userFreePoint} point 所持
                    </div>
                  </Grid>
                  <div style={{ height: "70vh" }}></div>
                </Grid>
              </>
            )}

            {/* <Grid item xs={6}>
                <Button
                  disabled={false}
                  size="large"
                  variant="contained"
                  onClick={() => {
                    pointAdd(userId);
                  }}
                  style={{ fontSize: 20 }}
                >
                  毎月ポイント追加
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={false}
                  size="large"
                  variant="contained"
                  onClick={() => {
                    pointSubMakeRon("none");
                  }}
                  style={{ fontSize: 20 }}
                >
                  ポイント利用
                </Button>
              </Grid> */}
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};
export default PaymentHistory;
